import { Image, Menu, Modal, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Severty, ShowToast } from "../../helper/toast";

import Logo from "../../assets/images/Logo.png";
import Logo22 from "../../assets/images/Logo22.png";
import MyClient from "../../assets/images/side_nav/myClient.svg";
import MyAsset from "../../assets/images/side_nav/myAsset.svg";
import PublicAsset from "../../assets/images/side_nav/publicAsset.svg";
import MyAccount from "../../assets/images/side_nav/customer.svg";
import MyTask from "../../assets/images/side_nav/myTask.svg";
import myDocument from "../../assets/images/side_nav/myDocument.svg";
import Message from "../../assets/images/side_nav/message.svg";
import TransactionManagement from "../../assets/images/side_nav/transactionManagement.svg";
import Dash from "../../assets/images/side_nav/dash.svg";
import Log from "../../assets/images/side_nav/log.svg";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import DeleteModal from "../DeleteModal";
import { useAppContext } from "../../context/AppContext";
import moment, { isMoment } from "moment";
import { toBeDisabled } from "@testing-library/jest-dom/matchers";

export const countryWithAreas = [
  "646b2e0f46865f1f65565346", //Palestine
];

export const menuItems = [
  {
    key: "dashboard-management",
    path: "/dashboard",
    icon: Dash,
    label: lang("Dashboard"),
    isShow: false,
  },
  {
    key: "client-manager",
    label: `${lang("My Client")}`,
    icon: MyClient, //
    path: "/my-client",
  },
  {
    key: "my-assets",
    label: `${lang("My Asset")}`,
    icon: MyAsset,
    path: "/asset",
  },
  {
    key: "public-assets",
    label: `${lang("Public Asset")}`,
    icon: PublicAsset,
    path: "/public-asset",
  },
  {
    key: "owner",
    label: `${lang("Owners")}`,
    icon: MyTask,
    path: "/owner",
  },
  {
    key: "my-task",
    label: `${lang("My Task")}`,
    icon: MyTask,
    path: "/my-task",
  },
  {
    key: "my-document",
    label: `${lang("My Document")}`,
    icon: myDocument,
    path: "/my-document",
  },
  {
    key: "message",
    label: `${lang("Message")}`,
    icon: Message,
    path: "/messege",
  },
  // {
  //   key: "transaction-management",
  //   label: `${lang("Transaction Management")}`,
  //   icon: TransactionManagement,
  //   path: "/transaction-management",
  // },
  {
    key: "account",
    label: `${lang("My Account")}`,
    icon: MyAccount,
    path: "/account",
  },
  // {
  //   key: "Contact",
  //   label: `${lang("")} ${lang("Contact")}`,
  //   icon: Coust, //
  //   path: "/contact",
  // },
  // {
  //   key: "email-template-manager",
  //   path: "/email-templates",
  //   icon: Disk,
  //   label: `${lang("Email Template")} ${lang("Management")}`,
  // },
  // {
  //   key: "cms-manager",
  //   path: "/cms",
  //   icon: Cms,
  //   label: `${lang("CMS")} ${lang("Management")}`,
  // },
];

const deliveryChargeManager = [
  {
    key: "delivery-charge-manager",
    path: "/delivery-charge",
    icon: MyAccount,
    label: `${lang("Delivery Charge")} ${lang("Management")}`,
  },
];

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { country } = useAppContext();

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
    // logout();
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) return;
    if (userProfile.type == "Admin") {
      const items =
        country &&
          country?.country_id &&
          countryWithAreas.includes(country?.country_id)
          ? [...menuItems, ...deliveryChargeManager]
          : menuItems;

      setMenuLinks(items);
      setLoading(false);
      return;
    }

    const items =
      country &&
        country?.country_id &&
        countryWithAreas.includes(country?.country_id)
        ? [...menuItems, ...deliveryChargeManager]
        : menuItems;

    const newArray = items.filter((item) => {
      if (item.isShow) {
        return true;
      } else {
        //return userProfile.permission.includes(item.key);
        return userProfile;
      }
    });

    const links = newArray.filter((item) => {
      if (item?.children?.length) {
        return true;
      } else if (!item?.children) {
        return true;
      } else {
        return false;
      }
    });

    setMenuLinks(links);
    setLoading(false);
  }, [userProfile, country]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          <div className="brand-logo">
            <NavLink to="" className="imgOuter">
              <img className="logo-light" src={Logo} alt="" />
              <img className="logo-dark" src={Logo22} alt="" />
            </NavLink>
            {/* <p style={{}}>
              {currentDateTime.format("YYYY-MM-DD hh:mm a")}
            </p> */}
          </div>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map((item) => {
              if (item.children) {
                return (
                  <>
                    <Menu.SubMenu
                      key={item.key}
                      title={
                        <>
                          <span className="icon">
                            <Image preview={false} src={item.icon} />
                          </span>
                          <span className="label">{item.label}</span>
                        </>
                      }
                    >
                      {item.children.map((child) => (
                        <Menu.Item key={child.key}>
                          <NavLink to={child.path}>{child.label}</NavLink>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  </>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>{renderTitle(item)}</NavLink>
                </Menu.Item>
              );
            })}

            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={"#"}>
                <>
                  <Image preview={false} src={Log} />
                  <span className="label">{lang("Logout")}</span>
                </>
              </NavLink>
            </Menu.Item>
          </Menu>
        </>
      )}
      {isLogoutModalVisible && (
        <DeleteModal
          title={lang("Logout")}
          subtitle={lang("Are you sure you want to Logout the Application?")}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={async () => {
            setIsLogoutModalVisible(false);
            try {
              await logout();
            } catch (error) {
              console.error("Logout error:", error);
              ShowToast("Logout failed", Severty.ERROR);
            }
          }}
        />
      )}
    </>
  );
}

export default Sidenav;
