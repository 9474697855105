import {
    Col,
    Row,
    Select,
    Tabs,
    Typography,
    Image,
    Avatar,
    Checkbox,
  } from "antd";
  import React, { useContext, useEffect, useState } from "react";
  import { useParams } from "react-router-dom";
  import {
    SettingFilled,
    UserOutlined,
  } from "@ant-design/icons";
  import apiPath from "../../constants/apiPath"; 
  import { AppStateContext } from "../../context/AppContext";
  import { Severty, ShowToast } from "../../helper/toast";
  import useRequest from "../../hooks/useRequest";
  import WhatsAppIcon from "../../assets/images/whatsapp-icon.svg";
  import LinkedinIcon from "../../assets/images/linkdin-icon.svg";
  import SkypeIcon from "../../assets/images/skype-icon.svg";
  import Timeicon from "../../assets/images/time-icon.svg";
  import GmailIcon from "../../assets/images/gmail-icon.svg";
  import CallingIcon from "../../assets/images/calling-icon.svg";
  import LocationIcon from "../../assets/images/location-icon-01.svg";
  import pdfImg from "../../assets/images/pdf-photo.png";
  import { useAuthContext } from "../../context/AuthContext";
  import lang from "../../helper/langHelper";
  
  const data = [
    {
      key: '1',
      size: '#225555',
      client: 'Ronald Richards',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
  
  
    },
    {
      key: '2',
      size: '#23134855',
      client: 'Darrell Steward',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
    },
    {
      key: '3',
      size: '#54394837',
      client: 'Marvin McKinney',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    },
    {
      key: '4',
      size: '#54394837',
      client: 'Ronald Richards',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    },
    {
      key: '5',
      size: '#54394837',
      client: 'Ronald Richards',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    },
    {
      key: '6',
      size: '#54394837',
      client: 'Marvin McKinney',
      date: '11 Jun, 2024',
      Uploader: 'Catallina Blomquist',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    },
  ];
  
  const columns = [
    {
      title: '',
      dataIndex: 'check',
      className: "transiation-roww-0",
  
      render: (text, record) => (
        <span>
          <Checkbox />
  
        </span>
      ),
    },
  
  
    {
      title: 'NAME',
      dataIndex: 'client',
      className: "transiation-roww",
  
      render: (text, record) => (
        <span>
          <Avatar src={record.avatar} icon={<UserOutlined />} /> {text}
  
        </span>
      ),
    },
  
  
    {
      title: 'Size',
      dataIndex: 'size',
      className: "transiation-roww-2",
    },
  
    {
      title: 'Date Created',
      dataIndex: 'date',
      className: "transiation-roww-3",
    },
    {
      title: 'File Type',
      dataIndex: 'pdf',
      className: "transiation-roww-4",
      render: (text, record) => (
        <span>
          <img src={pdfImg} /> {text}
        </span>
      ),
    },
    {
      title: 'Uploaded By',
      dataIndex: 'Uploader',
      className: "transiation-roww-5",
    },
  ];
  
  const { TabPane } = Tabs;
  const { Title, Text } = Typography;
  
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const years = [2024, 2023];
  const { Option } = Select;
  
  function Index({ }) {
    const { setPageHeading, country } = useContext(AppStateContext);
    const { request } = useRequest();
    const [year, setYear] = useState(years[0]);
    const [loading, setLoading] = useState(years[0]);
    const { userProfile, isAdmin } = useAuthContext();
    const [dashboard, setDashboard] = useState();
    const { id } = useParams();
  
    const handleChange = (value) => {
      console.log(`selected ${value}`);
      setYear(value);
    };
  
    useEffect(() => {
      setPageHeading(lang("Welcome Broker"));
      if (!userProfile) return;
  
      if (userProfile.type == "SubAdmin") {
        setPageHeading(`Welcome ${userProfile.name}`);
        if (!userProfile?.permission.includes("dashboard-management")) return;
      }
      setLoading(true);
      request({
        url: apiPath.dashboard + `${year ? `?year=${year}` : ""}`,
        method: "GET",
        onSuccess: (data) => {
          setLoading(false);
          setDashboard(data.data);
        },
        onError: (error) => {
          setLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    }, [year, country.country_id, userProfile]);
    return (
      <>
        <Row gutter={[24,24]}>
          <Col span={24} md={8} lg={8} xl={6}>
            <ClientDetails id={id} />
          </Col>
          <Col span={24} md={16} lg={16} xl={18}>
           

<div className="booking-notification">

<div className="today-notifiaction">
<div className="notidiaction-day">
Today
</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-danger for-right-aligne ">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>

</div>

<div className="today-notifiaction">
<div className="notidiaction-day">
Yesterday
</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-success for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>

</div>


<div className="today-notifiaction">
<div className="notidiaction-day">
22 September
</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-danger for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-success for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-danger for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-danger for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-success for-right-aligne">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>

</div>
<div className="about-notifiaction">
<div className="booking-id">
<h3>Booking <span>#54234</span></h3>
<p>24 September | 7:30 AM</p>
</div>
<div className="booking-id">
<h3 className="text-success for-right-aligne ">+ $500 .00</h3>
<p>Balance $12,000 .00</p>
</div>
</div>
</div>
</div>





           
          </Col>
        </Row>
      </>
    );
  }
  // Client Details
  const ClientDetails = ({ id }) => {
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [year, setYear] = useState(years[0]);
    const [clientDetails, setClientDetails] = useState();
    const { userProfile } = useAuthContext();
  
    const getClientData = () => {
      request({
        url: apiPath.viewClientDetail + "/" + id,
        method: 'GET',
        onSuccess: (data) => {
          console.log("data----", data);
          setLoading(false)
          if (data.status) {
            setLoading(true);
            setClientDetails(data.data)
          } else {
            ShowToast(data.message, Severty.ERROR)
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR)
          setLoading(false)
        },
      })
    }
    const handleChange = (value) => {
      console.log(`selected ${value}`);
      setYear(value);
    };
  
    useEffect(() => {
      getClientData()
    }, [])
  
    return (
      <div className="user-profile-card">
        <div className="settings-icon">
          <SettingFilled />
        </div>
        {clientDetails && <div className="client-profile">
          <Image
            src={clientDetails?.image ? clientDetails.image : "/mnt/data/image.png"}
            alt="Profile"
            width={80}
            height={80}
            style={{ borderRadius: "50%" }}
          />
        </div>}
        <h2 className="name">
          {clientDetails?.name ? `${clientDetails.name} ${clientDetails.last_name}` : "-"}<span>Active</span>
        </h2>
        <p className="owner">Owner: Hugh, Adams</p>
        <div className="lead-tags">
          <span className="lead-tag new-lead sky-teg">New Lead</span>
          <span className="lead-tag new-lead yellow-teg">New Lead</span>
          <span className="lead-tag new-lead purpal-teg">New Lead</span>
          <a className="lead-tag add-lead">+</a>
        </div>
        <div className="contact-info">
          <p className="time">
            <img src={Timeicon} /> 12:37 (CET)
          </p>
          <p className="location">
            <img src={LocationIcon} />
            {clientDetails ? clientDetails?.city : "-"}
          </p>
          <p className="email">
            <img src={GmailIcon} />
            {clientDetails ? clientDetails?.email : "-"}
          </p>
          <p className="phone">
            <img src={CallingIcon} />
            {clientDetails ? clientDetails?.countryCode + "-" + clientDetails.mobileNumber : "-"}
          </p>
        </div>
        <div className="social-icons">
          <img src={WhatsAppIcon} className="social-icon whatsapp" />
          <img src={LinkedinIcon} className="social-icon linkedin" />
          <img src={SkypeIcon} className="social-icon skype" />
        </div>
        <button className="send-email-btn">Send Email</button>
        <div className="important-info">
          <h3>Important Information</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore enim ad minim.
          </p>
        </div>
      </div>
    )
  }
  

  export default Index;