import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Tag,
  Divider,
  Tabs,
  Form,
  Spin,
} from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import HomeMedia from "../../assets/images/home.jpeg";
import HomeImage from "../../assets/images/home.jpeg";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const IndexView = () => {
  const [openLiteBox, setOpenLiteBox] = useState(false);
  const mapContainerRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState();

  const geocoderContainerRef = useRef(null);

  const params = useParams();
  const sectionName = params.id ? "Edit Asset" : "Create Asset";
  const heading = " Asset Management";
  const api = {
    add: apiPath.addAsset,
    edit: apiPath.editAsset,
    singleAsset: apiPath.singleAsset,
    listforMap: apiPath.listforMap,
    cityForMore: apiPath.cityForMore,
  };
  const [form] = Form.useForm();
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [singleAsset, setsingleAsset] = useState({});
  const [properties, setProperties] = useState([]);
  const [cityForMore, setCityForMore] = useState();
  const [similarData, setSimilarData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const { logout, userProfile, isAdmin, setIsAdmin } = useContext(AuthContext);
  useEffect(() => {
    setLoading(true);
    fetchAsset(params.id);
  }, [params.id]);

  // useEffect(() => {
  //   request({
  //     url: `${api.listforMap}`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       // console.log(data.data);
  //       setProperties((prevProperties) => [
  //         ...prevProperties,
  //         ...data.data.data,
  //       ]);
  //     },
  //   });
  // }, []);

  const fetchAsset = (id) => {
    setLoading(true);
    request({
      url: `${api.singleAsset + "/" + id}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        setsingleAsset(data.data);
        setProperties(data.similarAll);
        setSimilarData(data.similar);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  // const getCitiesData = () => {
  //   request({
  //     url: `${api.cityForMore}?city=${cityForMore}`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setProperties(data.dataAll);
  //       setSimilarData(data.data);
  //     },
  //   });
  // }
  // useEffect(() => {
  //   request({
  //     url: `${api.cityForMore}?city=${cityForMore}`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       // setProperties((prevProperties) => [
  //       //   ...prevProperties,
  //       //   ...data.dataAll,
  //       // ]);
  //       setProperties(data.dataAll);
  //       setSimilarData(data.data);
  //     },
  //   });
  // }, [cityForMore]);

  // useEffect(() => {
  //   if (!mapContainerRef.current) return; // Ensure the ref is set

  //   // Initialize the Mapbox map
  //   const map = new mapboxgl.Map({
  //     container: mapContainerRef.current,
  //     style: "mapbox://styles/mapbox/streets-v12",
  //     center:
  //       [singleAsset.longitude, singleAsset.latitude],
  //     // Initial map center [longitude, latitude]
  //     // Initial map center [longitude, latitude]
  //     zoom: 14, // Initial map zoom level
  //   });

  //   map.addControl(new mapboxgl.FullscreenControl());

  //   map.on("load", () => {
  //     // Add your property data source with clustering enabled
  //     map.addSource("properties", {
  //       type: "geojson",
  //       data: {
  //         type: "FeatureCollection",
  //         features: properties.map((property) => ({
  //           type: "Feature",
  //           geometry: {
  //             type: "Point",
  //             coordinates: [property.longitude, property.latitude],
  //           },
  //           properties: {
  //             total_price: property.total_price,
  //             address: property.location.city,
  //             rooms: property.rooms_number,
  //             floor: property.floor_number,
  //             property_type: property.property_type,
  //             size: property.size_in_meters,
  //             _id: property._id,
  //           },
  //         })),
  //       },
  //       cluster: true,
  //       clusterMaxZoom: 10,
  //       clusterRadius: 45,
  //     });

  //     // Add a layer to display the clusters
  //     map.addLayer({
  //       id: "clusters",
  //       type: "circle",
  //       source: "properties",
  //       filter: ["has", "point_count"],
  //       paint: {
  //         "circle-color": "#51bbd6", // Single color for all clusters
  //         "circle-radius": 20, // Single radius for all clusters
  //       },
  //     });

  //     // Add a layer for the cluster count
  //     map.addLayer({
  //       id: "cluster-count",
  //       type: "symbol",
  //       source: "properties",
  //       filter: ["has", "point_count"],
  //       layout: {
  //         "text-field": "{point_count}", // Use point_count instead of point_count_abbreviated
  //         "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
  //         "text-size": 12,
  //       },
  //     });

  //     // Add a layer for unclustered points
  //     map.addLayer({
  //       id: "unclustered-point",
  //       type: "circle",
  //       source: "properties",
  //       filter: ["!", ["has", "point_count"]],
  //       paint: {
  //         "circle-color": "#11b4da",
  //         "circle-radius": 8,
  //         "circle-stroke-width": 1,
  //         "circle-stroke-color": "#fff",
  //       },
  //     });

  //     map.addLayer({
  //       id: "centered-point",
  //       type: "circle",
  //       source: "properties",
  //       filter: [
  //         "all",
  //         ["!", ["has", "point_count"]],
  //         ["==", ["get", "is_center"], true],
  //       ],
  //       paint: {
  //         "circle-color": "#cf692d", // Red color for the centered property
  //         "circle-radius": 8, // Larger radius for the centered property
  //         "circle-stroke-width": 2,
  //         "circle-stroke-color": "#fff",
  //       },
  //     });

  //     // Add popups for unclustered points
  //     map.on("click", "unclustered-point", (e) => {
  //       const coordinates = e.features[0].geometry.coordinates.slice();
  //       const { total_price, address, rooms, floor, property_type, size, _id } =
  //         e.features[0].properties;

  //       new mapboxgl.Popup()
  //         .setLngLat(coordinates)
  //         .setHTML(
  //           `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">$${total_price}</h3>
  //                   <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
  //                     <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  //                       <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
  //                       <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
  //                     </svg><span>${address}</span></div>
  //                   <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
  //                     <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
  //                     <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
  //                     <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
  //                     <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
  //                   </ul></div></a>`
  //         )
  //         .addTo(map);
  //     });

  //     // Zoom to cluster expansion on click
  //     map.on("click", "clusters", (e) => {
  //       const features = map.queryRenderedFeatures(e.point, {
  //         layers: ["clusters"],
  //       });

  //       console.log(features, "features");
  //       const clusterId = features[0].properties.cluster_id;
  //       map
  //         .getSource("properties")
  //         .getClusterExpansionZoom(clusterId, (err, zoom) => {
  //           if (err) return;

  //           map.easeTo({
  //             center: features[0].geometry.coordinates,
  //             zoom,
  //           });
  //         });
  //     });

  //     map.on("mouseenter", "clusters", () => {
  //       map.getCanvas().style.cursor = "pointer";
  //     });
  //     map.on("mouseleave", "clusters", () => {
  //       map.getCanvas().style.cursor = "";
  //     });
  //   });

  //   // Cleanup on unmount
  //   return () => map.remove();
  // }, [properties]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Static image URLs for demo purposes
  const images = [
    "https://via.placeholder.com/600x400", // Replace with your actual images
    "https://via.placeholder.com/600x400?text=Second+Image",
    "https://via.placeholder.com/600x400?text=Third+Image",
    "https://via.placeholder.com/600x400?text=Fourth+Image",
  ];

  const openImageInLightbox = (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    const scrollContainerRef = useRef(null);
    const [showScrollUpIcon, setShowScrollUpIcon] = useState(false);
    const [showScrollDownIcon, setShowScrollDownIcon] = useState(false);

    useEffect(() => {
      const checkOverflow = () => {
        const element = scrollContainerRef.current;
        if (element) {
          setShowScrollDownIcon(element.scrollHeight > element.clientHeight);
          setShowScrollUpIcon(element.scrollTop > 0);
        }
      };

      checkOverflow();
      window.addEventListener("resize", checkOverflow);
      return () => window.removeEventListener("resize", checkOverflow);
    }, []);
    const handleScroll = () => {
      const element = scrollContainerRef.current;
      if (element) {
        setShowScrollUpIcon(element.scrollTop > 0);
        setShowScrollDownIcon(
          element.scrollTop + element.clientHeight < element.scrollHeight
        );
      }
    };

    const scrollUp = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          top: -100,
          behavior: "smooth",
        });
      }
    };

    const scrollDown = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          top: 100,
          behavior: "smooth",
        });
      }
    };
    return (
      <div
        className="carousel-button-group w-20"
        style={{
          height: "100%",
          top: 0,
          left: 0,
          position: "absolute",
        }}
      >
        <div
          className="carousel-button-group-container"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {showScrollUpIcon && (
            <div className="custom-scroll-up" onClick={scrollUp}>
              <i class="fa-solid fa-angle-up"></i>
            </div>
          )}
          {rest.item.map((val, index) => {
            return (
              <span
                className={currentSlide === index ? "active" : ""}
                onClick={() => goToSlide(index)}
              >
                <img src={val} alt="products" />
              </span>
            );
          })}
          {showScrollDownIcon && (
            <div className="custom-scroll-down" onClick={scrollDown}>
              <i class="fa-solid fa-angle-down"></i>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onHandleClick = (id) => {
    setLoading(true);
    request({
      url: apiPath.editAsset + "/" + id,
      method: "PUT",
      data: {
        broker_id: true,
      },

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {loading ? (
        <div className="spiner-box">
          <Spin />
        </div>
      ) : (
        <>
          <Card>
            <div className="property-gallery demo">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                    {lang("Back")}
                  </Button>
                </Col>
                <Col span={24}>
                  <div className="title-details">
                    <div className="inner-details">
                      <h2>{singleAsset?.property_title}</h2>
                      <div className="property-sub-details d-flex">
                        <span>
                          {singleAsset.location?.city}{" "}
                          {singleAsset.location?.neighborhood}{" "}
                          {singleAsset.location?.street === "0"
                            ? ""
                            : singleAsset.location?.street}{" "}
                          {singleAsset.location?.street_number === "0"
                            ? ""
                            : singleAsset.location?.street_number}{" "}
                        </span>{" "}
                        <div className="property-status">
                          {lang("for")}  {singleAsset?.to_let === "rent" ? lang('Rent') : singleAsset?.to_let === "commercial_buy" ? lang('commercial_buy') : singleAsset?.to_let === "commercial_rent" ? lang('commercial_rent') : lang('Sale')}
                        </div>
                      </div>
                      <div className="list-menubar02s">
                        <ul className="list-unstyled">
                          <li>
                            <div className="item-name">
                              <img
                                loading="lazy"
                                src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg"
                                alt="Palm Dom 4 Hadera"
                                className="img-fluid"
                              />
                              <span>{lang("rooms")}</span>
                            </div>
                            <span className="conte">
                              {singleAsset?.rooms_number}
                            </span>
                          </li>
                          <li>
                            <div className="item-name">
                              <img
                                loading="lazy"
                                src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg"
                                alt="Palm Dom 4 Hadera"
                                className="img-fluid"
                              />
                              <span>{lang("floor")}</span>
                            </div>
                            <span className="conte">
                              {singleAsset?.floor_number}
                            </span>
                          </li>
                          <li>
                            <div className="item-name">
                              <img
                                loading="lazy"
                                src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg"
                                alt="Palm Dom 4 Hadera"
                                className="img-fluid"
                              />
                              <span>{lang("Property Type")}</span>
                            </div>
                            <span className="conte">
                              {singleAsset?.property_type}
                            </span>
                          </li>
                          <li>
                            <div className="item-name">
                              <img
                                loading="lazy"
                                src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg"
                                alt="Palm Dom 4 Hadera"
                                className="img-fluid"
                              />
                              <span>{lang("size")}</span>
                            </div>
                            <span className="conte">
                              {singleAsset?.size_in_meters}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="right-content">
                      <div className="social-btn-list social-btn-list-main">
                        {!singleAsset?.broker_id?.includes(userProfile._id) && (
                          <Button
                            onClick={() => onHandleClick(singleAsset._id)}
                            className="add-btn"
                          >
                            <PlusOutlined />
                          </Button>
                        )}
                        <a
                          type="button"
                          href={`https://wa.me/${singleAsset.mobile_number}`}
                          // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${singleAsset._id}`}
                          // href={`https://wa.me/+${item.country_code}${item.mobile_number}`}
                          target="_blank"
                          title={lang("Share Property")}
                          data-id="135362"
                          className="wishlisted"
                        >
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="WhatsAppIcon"
                          >
                            <path
                              fill="white"
                              d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                      <h3>
                        {new Intl.NumberFormat().format(
                          singleAsset?.total_price
                        )}{" "}
                        ₪
                      </h3>
                    </div>
                  </div>

                  <div className="property-detail-block">
                    <Row gutter={[16, 16]}>
                      <Col span={24} lg={12}>
                        <div className="wrapper-tab-v9 mb-3">
                          <Tabs
                            defaultActiveKey={selectedTab}
                            className="myasset-tab"
                            onTabClick={(e) => {
                              setSelectedTab(e);
                            }}
                          >
                            <TabPane
                              tab={
                                <span className="myclients-tab-button">
                                  {" "}
                                  {lang("Overview")}
                                </span>
                              }
                              key="all"
                              className="myclients-tab-body"
                            >
                              <div className="listing-details">
                                <div className="details-section">
                                  <h5 class="desc-heading mb-3"> {lang("Details")} </h5>
                                  <ul className="feature-list list-overview d-flex flex-wrap">
                                    <li className="d-flex align-items-center">
                                      <div className="icon-sprit">
                                        <img
                                          loading="lazy"
                                          src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg"
                                          alt="Palm Dom 4 Hadera"
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="details flex-grow-1">
                                        <span className="text-value">
                                          {lang("rooms")}
                                        </span>
                                        <span className="value0-details">
                                          {singleAsset?.rooms_number}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <div className="icon-sprit">
                                        <img
                                          loading="lazy"
                                          src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg"
                                          alt="Palm Dom 4 Hadera"
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="details flex-grow-1">
                                        <span className="text-value">
                                          {lang("floor")}
                                        </span>
                                        <span className="value0-details">
                                          {singleAsset?.floor_number}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <div className="icon-sprit">
                                        <img
                                          loading="lazy"
                                          src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg"
                                          alt="Palm Dom 4 Hadera"
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="details flex-grow-1">
                                        <span className="text-value">{lang("size")}</span>
                                        <span className="value0-details">
                                          {singleAsset?.size_in_meters}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="details-section">
                                  <h5 class="desc-heading mb-3">
                                    {" "}
                                    {lang("Description")}{" "}
                                  </h5>
                                  <p>{singleAsset?.property_description}</p>
                                </div>
                                <div className="details-section">
                                  <h5 class="desc-heading mb-3"> {lang("Property")} </h5>
                                  <ul className="list list-detail">
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.bar ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p> {lang("Bar")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.elevator ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Elevator")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.airConditioning ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Air-Conditioning")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.parking ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Parking")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.handicapAccessibility ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Handicap Accessibility")} </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.warehouse ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Storage")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.dimension ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Dimension")}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="text flex-shrink-0">
                                        {!singleAsset.balcony ? (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2131_2524)">
                                              <path
                                                d="M19.2946 3.77489C19.2467 3.71989 19.1978 3.66489 19.1483 3.60989C19.0288 3.47826 18.9054 3.34882 18.7781 3.22158C18.6509 3.09435 18.5215 2.97105 18.3898 2.8517C18.3348 2.8022 18.2798 2.75325 18.2248 2.7054C13.9042 -1.06657 7.33738 -0.894142 3.22165 3.22186C-0.894073 7.33786 -1.0665 13.9044 2.70519 18.2253C2.8702 18.4143 3.04235 18.5987 3.22165 18.7783C3.40096 18.958 3.5853 19.1302 3.77469 19.2948C8.09557 23.0665 14.6621 22.8943 18.7781 18.7783C22.8941 14.6623 23.0666 8.09577 19.2946 3.77489ZM1.51222 11.0001C1.51213 9.18798 2.031 7.41373 3.00746 5.88719C3.98393 4.36065 5.37714 3.14569 7.02233 2.38598C8.66752 1.62627 10.4959 1.3536 12.2911 1.60023C14.0864 1.84685 15.7735 2.60244 17.1529 3.77764L3.77744 17.1531C2.30951 15.4397 1.50565 13.2563 1.51222 11.0001ZM17.71 17.7102C16.0233 19.397 13.7631 20.3866 11.3796 20.4818C8.99608 20.577 6.66415 19.7709 4.84831 18.2239L13.7615 9.308L16.129 6.94048L18.2224 4.84714C19.7699 6.6629 20.5765 8.99502 20.4815 11.3789C20.3866 13.7627 19.397 16.0233 17.71 17.7102Z"
                                                fill="black"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2131_2524">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="11"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2130_2518)">
                                              <path
                                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14L15.5 8Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M15.5 8L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14L6.5 11Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M6.5 11L9.5 14"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2130_2518">
                                                <rect
                                                  width="22"
                                                  height="22"
                                                  rx="2"
                                                  fill="white"
                                                ></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        )}
                                        <p>{lang("Balcony")}</p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane
                              tab={
                                <span className="myclients-tab-button">
                                  {" "}
                                  {lang("Media")}
                                </span>
                              }
                              key="media"
                              className="myclients-tab-body"
                            >
                              <div className="listing-details">
                                {/* <div className="details-section">
                         <h5 class="desc-heading mb-3"> No media found! </h5>
                       </div> */}
                                <div className="media-listed">
                                  {singleAsset.multiImage?.length > 0 ? (
                                    <ul>
                                      {singleAsset.multiImage?.map((index) => (
                                        <li>
                                          <div className="media-items">
                                            <img src={index} />
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    lang("No media found!")
                                  )}
                                </div>
                              </div>
                            </TabPane>
                            <TabPane
                              tab={
                                <span className="myclients-tab-button">
                                  {" "}
                                  {lang('Location')}
                                </span>
                              }
                              key="location"
                              className="myclients-tab-body"
                            >
                              <Map
                                mapContainerRef={mapContainerRef}
                                properties={properties}
                                singleAsset={singleAsset}
                              />
                            </TabPane>
                          </Tabs>
                        </div>
                      </Col>
                      <Col span={24} lg={12}>
                        <div>
                          {/* <div className="image-gallery-container">
                        <div className="main-image-section">
                              <img
                                src={images[photoIndex]}
                                alt={`Main Image ${photoIndex + 1}`}
                                className="main-image"
                              />
                            </div>
                            <div className="thumbnail-gallery">
                              <Carousel
                                responsive={responsive}
                                arrows={true}
                                itemClass="thumbnail-item"
                                className="thumbnail-carousel"
                              >
                                {images.map((image, index) => (
                                  <div key={index} onClick={() => openImageInLightbox(index)}>
                                    <img
                                      src={image}
                                      alt={`Thumbnail ${index + 1}`}
                                      className="thumbnail-img"
                                    />
                                  </div>
                                ))}
                              </Carousel>
                            </div>

                           

                            {openLightbox && (
                              <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => setOpenLightbox(false)}
                                onMovePrevRequest={() =>
                                  setPhotoIndex((photoIndex + images.length - 1) % images.length)
                                }
                                onMoveNextRequest={() =>
                                  setPhotoIndex((photoIndex + 1) % images.length)
                                }
                              />
                            )}
                          </div> */}
                          <div className="">
                            {singleAsset.multiImage && (
                              <div className="">
                                <Carousel
                                  arrows={true}
                                  responsive={responsive}
                                  customButtonGroup={
                                    <ButtonGroup
                                      item={singleAsset.multiImage}
                                    />
                                  }
                                  itemClass="d-flex justify-content-end"
                                  className="product-image"
                                >
                                  {singleAsset.multiImage.length > 0 &&
                                    singleAsset.multiImage.map((val, index) => (
                                      <div
                                        className="w-80"
                                        key={val}
                                        onClick={() => {
                                          setOpenLiteBox(!openLiteBox);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        {val ? (
                                          <img
                                            src={val}
                                            id="myimage"
                                            className="d-block w-100"
                                            alt="products"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = HomeImage;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={HomeImage}
                                            id="myimage"
                                            className="d-block w-100"
                                            alt="products"
                                          />
                                        )}
                                      </div>
                                    ))}
                                </Carousel>
                              </div>
                            )}
                          </div>
                          {openLiteBox && (
                            <div className="lightbox">
                              <Lightbox
                                wrapperClassName="lightbox-wrapper"
                                mainSrc={singleAsset.multiImage[photoIndex]}
                                reactModalProps={{ Button: true }}
                                nextSrc={
                                  singleAsset.multiImage[
                                  (photoIndex + 1) %
                                  singleAsset.multiImage.length
                                  ]
                                }
                                prevSrc={
                                  singleAsset.multiImage[
                                  (photoIndex +
                                    singleAsset.multiImage.length -
                                    1) %
                                  singleAsset.multiImage.length
                                  ]
                                }
                                onCloseRequest={() => setOpenLiteBox(false)}
                                onMovePrevRequest={() =>
                                  setPhotoIndex(
                                    (photoIndex +
                                      singleAsset.multiImage.length -
                                      1) %
                                    singleAsset.multiImage.length
                                  )
                                }
                                onMoveNextRequest={() =>
                                  setPhotoIndex(
                                    (photoIndex + 1) %
                                    singleAsset.multiImage.length
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                        {/* <div className="slider-pro-block">
                          <a>
                            <img
                              src={
                                singleAsset.multiImage?.length > 0
                                  ? singleAsset.multiImage[0]
                                  : HomeImage
                              }
                            />
                          </a>
                        </div> */}
                        <div className="author-wrapper mt-3">
                          <h5>{lang('Advertiser of the ad')}</h5>
                          <div className="author-content">
                            <ul>
                              <li>
                                <div className="author-name">
                                  <div className="icon">
                                    <UserOutlined />
                                  </div>
                                  <span>{singleAsset?.advertiser_name}</span>
                                </div>
                              </li>
                              <li>
                                <div className="author-name">
                                  <a href={"tel:" + singleAsset?.mobile_number}>
                                    <div className="icon">
                                      <PhoneOutlined />
                                    </div>
                                  </a>
                                  <span>
                                    {/* href=
                           {`https://wa.me/+972${singleAsset.mobile_number}`} */}

                                    {singleAsset?.mobile_number}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="similar-section">
              <div className="section-headding">
                <h2>Similar Properties</h2>
              </div>
              <Swiper
                slidesPerView={4}
                spaceBetween={20}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                // pagination={{
                //   clickable: true,
                // }}
                // modules={[Pagination]}
                className="mySwiper"
              >
                {similarData &&
                  similarData?.map((data) => (
                    <SwiperSlide>
                      {" "}
                      <a className="" href={"/asset-view/" + data._id}>
                        <div className="slider-pro-block">
                          <div className="swiper img-slider1">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <img
                                  loading="lazy"
                                  src={
                                    data.multiImage?.length > 0
                                      ? data.multiImage[0]
                                      : HomeImage
                                  }
                                />
                              </div>
                            </div>
                            <div className="swiper-nav-inner d-none">
                              <div className="swiper-inner-btn swiper-button-prev">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="swiper-inner-btn swiper-button-next">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="slider-block-content">
                            <div className="content-between mb-30">
                              <div className="price-locate-data">
                                <div className="price-block">
                                  {data.total_price} ₪
                                </div>
                                <div className="location">
                                  <svg
                                    width="13"
                                    height="15"
                                    viewBox="0 0 13 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>
                                    {data.location.city},{" "}
                                    {data.location.neighborhood}
                                  </span>
                                </div>
                              </div>
                              <a
                                // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                                // href={`https://wa.me/+${item.country_code}${item.mobile_number}`}
                                target="_blank"
                                type="button"
                                title="Add to wishlist"
                                data-id="135362"
                                className="wishlisted"
                              >
                                <svg
                                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="WhatsAppIcon"
                                >
                                  <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                </svg>
                              </a>
                            </div>
                            <ul className="list-unstyled mb-0 property-list">
                              <li>
                                <div className="item-name">
                                  <img
                                    loading="lazy"
                                    src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg"
                                    alt="Palm Dom 4 Hadera"
                                    className="img-fluid"
                                  />
                                  <span>rooms</span>
                                </div>
                                <span>{data.rooms_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img
                                    loading="lazy"
                                    src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg"
                                    alt="Palm Dom 4 Hadera"
                                    className="img-fluid"
                                  />
                                  <span>floor</span>
                                </div>
                                <span>{data.floor_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img
                                    loading="lazy"
                                    src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg"
                                    alt="Palm Dom 4 Hadera"
                                    className="img-fluid"
                                  />
                                  <span>Property Type</span>
                                </div>
                                <span>{data.property_type}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img
                                    loading="lazy"
                                    src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg"
                                    alt="Palm Dom 4 Hadera"
                                    className="img-fluid"
                                  />
                                  <span>size</span>
                                </div>
                                <span>{data.size_in_meters}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div> */}
          </Card>
        </>
      )}
    </>
  );
};

export default IndexView;

const Map = ({ mapContainerRef, properties, singleAsset }) => {
  const [hoveredFeature, setHoveredFeature] = useState(null);
  useEffect(() => {
    if (!mapContainerRef.current) return; // Ensure the ref is set
    if (!properties[0]?.longitude) return; // Ensure the ref is set

    // Initialize the Mapbox map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [singleAsset.longitude, singleAsset.latitude], // Initial map center [longitude, latitude]
      zoom: 14, // Initial map zoom level
    });

    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", () => {
      // Add your property data source with clustering enabled
      map.addSource("properties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: properties.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [property.longitude, property.latitude],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
              is_center:
                property.latitude === singleAsset.latitude &&
                property.longitude === singleAsset.longitude,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });

      // Add a layer to display the clusters
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "properties",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#51bbd6",
          "circle-radius": 20,
        },
      });

      // Add a layer for the cluster count
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "properties",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      // Add a layer for the centered point
      map.addLayer({
        id: "centered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["==", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#cf692d", // Red color for the centered property
          "circle-radius": 8, // Larger radius for the centered property
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        },
      });

      // Add a layer for unclustered points
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["!=", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });
      map.addLayer({
        id: "centered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["==", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#cf692d", // Red color for the centered property
          "circle-radius": 8, // Larger radius for the centered property
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        },
      });

      // Add a layer for unclustered points
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["!=", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      let pricePopup = null;
      let detailPopup = null;

      map.on("mouseenter", "unclustered-point", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      // Add popups for unclustered points
      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price, address, rooms, floor, property_type, size, _id } =
          e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });

      // Add popups for the centered point
      map.on("click", "centered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price, address, rooms, floor, property_type, size, _id } =
          e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });

      // Zoom to cluster expansion on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });

        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("properties")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    });
    // Cleanup on unmount
    return () => map.remove();
  }, [properties, mapContainerRef.current]);

  return (
    <div className="listing-details">
      <div className="details-section">
        <div style={{ width: "100%", height: "700px" }}>
          <div
            ref={mapContainerRef}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
