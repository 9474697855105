import {
  Button,
  Modal,
  Select,
  Slider,
  Switch,
  Col,
  Row,
  Tabs,
  Checkbox,
  Card,
  Collapse,
  Tooltip,
  Spin,
  Form,
  Input,
  Typography,
  Pagination,
  Table,
  Image,
} from "antd";
import {
  PlusOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import notfound from "../../assets/images/not_found.png";
import ConfirmationBox from "../../components/ConfirmationBox";
import apiPath from "../../constants/apiPath";
import RoomBed from "../../assets/images/bedroom-icon.svg";
import PropertyType from "../../assets/images/PropertyType.svg";
import SquareIcons from "../../assets/images/square-meters.svg";
import PriceIcon from "../../assets/images/price-icon.svg";
import SortByIcon from "../../assets/images/sort-by-icon.svg";
import ScreenOutIcon from "../../assets/images/screenout-icon.svg";
import CleanUpIcon from "../../assets/images/cleanup-icon.svg";
import BrokerIcons from "../../assets/images/broker-and-non.svg";
import ForrentIcon from "../../assets/images/for-rent-icon.svg";
import ForsaleIcon from "../../assets/images/for-sale-icon.svg";
import DoubleBedIcon from "../../assets/images/double-bed.svg";
import ProprtFlorIcon from "../../assets/images/building-icon.svg";
import HomeProprtyIcon from "../../assets/images/home-proprty.svg";
import SquareIcon from "../../assets/images/square.svg";
import HomeImage from "../../assets/images/home.jpeg";
import { useNavigate } from "react-router";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useGeolocated } from "react-geolocated";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AuthContext } from "../../context/AuthContext";
const { Option } = Select;
const searchResult = (query) =>
  query.map((cityData) => {
    return {
      value: cityData.city + "," + cityData.neighborhood,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            {" "}
            <svg
              className="svg-location"
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                stroke="#464A6B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                stroke="#464A6B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {cityData.city + "," + cityData.neighborhood}
          </span>
        </div>
      ),
    };
  });

const optionsAmeni = [
  {
    text: "Bars",
    value: "bars",
  },
  {
    text: "Elevator",
    value: "elevator",
  },
  {
    text: "Air Conditioning",
    value: "airConditioning",
  },
  {
    text: "Parking",
    value: "parking",
  },
  {
    text: "Handicap Accessibility",
    value: "handicapAccessibility",
  },
  {
    text: "Warehouse",
    value: "warehouse",
  },
  {
    text: "Dimension",
    value: "dimension",
  },
  {
    text: "Balcony",
    value: "balcony",
  },
];
const options12 = [
  {
    text: "Apartment",
    value: "דירה",
  },
  {
    text: "House / Cottage",
    value: "קוטג'",
  },
  {
    text: "Commercial Real Estate",
    value: "מִסְחָרִי מְקַרקְעִין",
  },
  {
    text: "Garden Apartment",
    value: "דירת גן",
  },
  {
    text: "Townhouse",
    value: "דו משפחתי",
  },

  {
    text: "Rooftop / Penthouse",
    value: "גג/ פנטהאוז",
  },
  {
    text: "Penthouse",
    value: "פנטהאוז",
  },
  {
    text: "Mini Penthouse",
    value: "מיני פנטהאוז",
  },
  {
    text: "Rooftop Apartment",
    value: "דירת גג",
  },

  {
    text: "Studio / Loft",
    value: "סטודיו/ לופט",
  },
  {
    text: "Duplex",
    value: "דופלקס",
  },
  {
    text: "Triplex",
    value: "טריפלקס",
  },
  {
    text: "Apartment Unit",
    value: "יחידת דיור",
  },
  {
    text: "Plots",
    value: "מגרשים",
  },
  {
    text: "Farmhouse / Homestead",
    value: "משק חקלאי/ נחלה",
  },
  {
    text: "Building",
    value: "בניין",
  },
  {
    text: "Divided Apartment",
    value: "דירה מחולקת",
  },
  {
    text: "Basement Apartment",
    value: "דירת מרתף",
  },
  {
    text: "Basement / Parter",
    value: "מרתף/ פרטר",
  },
  {
    text: "Residential Building",
    value: "בניין מגורים",
  },
  {
    text: "Vacation Apartment",
    value: "דירת נופש",
  },

  {
    text: "Parking",
    value: "חניה",
  },
  {
    text: "Storage",
    value: "מחסן",
  },
  {
    text: "Other",
    value: "אחר",
  },
];
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

mapboxgl.accessToken =
  "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";

function Index() {
  const mapContainerRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const heading = lang("Public") + " " + lang("Assets");
  const { setPageHeading } = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const navigate = useNavigate();
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [properties, setProperties] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState({ recordId: null });
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [clientDetails, setClientDetails] = useState();
  const [priceLimit, setPriceLimit] = useState([0, 10000000]);
  const [location, setLocation] = useState({ lat: "", lng: "" });
  const [searchCity, setSearchCity] = useState([]);
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [filterBroker, setFilterBroker] = useState();
  const [brokerActive, setBrokerActive] = useState();
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");
  const [filterType, setFilterType] = useState(null);
  const [months, setMonths] = useState([]);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [years, setYears] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedFiltersMulti, setSelectedFiltersMulti] = useState({
    size_in_meters: [null, null],
  });

  const [listParent, setListParent] = useState(false);

  const debouncedSearchFilter = useDebounce(selectedFilter, 300);
  const debouncedSearchFilterMulti = useDebounce(selectedFiltersMulti, 300);
  const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility
  const [activeButton, setActiveButton] = useState("buy");
  const debouncedActiveButton = useDebounce(activeButton, 300);
  const [sort, setSort] = useState(null);
  const [totalList, setTotalList] = useState(0);
  const debouncedSort = useDebounce(sort, 300);
  const [searchText, setSearchText] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;
  const [cities, setCities] = useState([]);
  const debouncedSearchArrayCity = useDebounce(cities, 600);
  const [sortedcities, setSortedCities] = useState([]);
  const [listTrue, setListTrue] = useState(true);
  const [searchSimilarCity, setSearchSimilarCity] = useState("");
  const debouncedSimilarCity = useDebounce(searchSimilarCity, 300);
  const [mapTrue, setMapTrue] = useState(true);
  const { logout, userProfile, isAdmin, setIsAdmin } = useContext(AuthContext);
  const [show, setShow] = React.useState(true);

  const [options, setOptions] = useState([]);
  const handleSearch = (value) => {
    // setSearchText(value);
    console.log(value);
    setSearchSimilarCity(value);
  };
  const onSelect = (value) => {
    console.log("onSelect", value);
    setSearchText(value);
  };

  useEffect(() => {
    // const sortedArray = cities.slice().sort((a, b) => a.length - b.length);
    const sortedArray = cities
      .slice()
      .sort((a, b) => Object.keys(a).length - Object.keys(b).length);
    setSortedCities(sortedArray);
    console.log(sortedArray);
  }, [debouncedSearchArrayCity]);

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const api = {
    status: apiPath.statusChangeAsset,
    public: apiPath.publicChangeAsset,
    list: apiPath.listAsset,
    update: apiPath.editAsset,
    delete: apiPath.deleteAsset,
    listforMap: apiPath.listforMap,
    edit: apiPath.editAsset,
  };

  useEffect(() => {
    request({
      url: `${api.listforMap}?page=${page}&debouncedSimilarCity=${debouncedSimilarCity}`,
      method: "GET",
      onSuccess: (data) => {
        if (debouncedSimilarCity && data?.data?.data?.length > 0) {
          searchSimilarCity.split(",").map((id) => {
            console.log(id);
            data?.data?.data.map((dataIn) => {
              setCities((prevCities = []) => {
                const newCities = [...prevCities];
                const existingEntry = newCities.find(
                  (entry) => entry.city === dataIn.location.city
                );
                if (!existingEntry) {
                  newCities.push({
                    city: dataIn.location.city,
                  });
                }
                return newCities;
              });
              setCities((prevCities = []) => {
                const newCities = [...prevCities];
                const existingEntry = newCities.find(
                  (entry) =>
                    entry.city === dataIn.location.city &&
                    entry.neighborhood === dataIn.location.neighborhood
                );
                if (!existingEntry) {
                  newCities.push({
                    city: dataIn.location.city,
                    neighborhood: dataIn.location.neighborhood,
                  });
                }
                return newCities;
              });
              setCities((prevCities = []) => {
                const newCities = [...prevCities];
                const existingEntry = newCities.find(
                  (entry) =>
                    entry.city === dataIn.location.city &&
                    entry.neighborhood === dataIn.location.neighborhood &&
                    entry.street === dataIn.location.street
                );
                if (!existingEntry) {
                  newCities.push({
                    city: dataIn.location.city,
                    neighborhood: dataIn.location.neighborhood,
                    street: dataIn.location.street,
                  });
                }
                return newCities;
              });
            });
          });
        }
      },
    });
  }, [debouncedSimilarCity]);

  const handleChangeMap = (e) => {
    setMapTrue(e);
  };
  const onChangeList = (e) => {
    setListTrue(e);
  };
  const onChangeParentList = (e) => {
    setListParent(e);
  };
  useEffect(() => {
    setOptions(cities.length > 0 ? searchResult(cities) : []);
  }, [cities]);

  useEffect(() => {
    // setLoading(true);
    request({
      url: `${api.listforMap}?page=${page}
          ${debouncedSearchCity
          ? `&debouncedSearchCity=${JSON.stringify(debouncedSearchCity)}`
          : ""
        }
          ${debouncedSearchFilter
          ? `&debouncedSearchFilter=${JSON.stringify(
            debouncedSearchFilter
          )}`
          : ""
        }
          ${debouncedSearchFilterMulti
          ? `&selectedFiltersMulti=${JSON.stringify(
            debouncedSearchFilterMulti
          )}`
          : ""
        }
          ${filterBroker ? `&broker_asset=${filterBroker}` : ""}
          ${debouncedActiveButton ? `&to_let=${debouncedActiveButton}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        // console.log(data.data);
        // setProperties((prevProperties) => [
        //   ...prevProperties,
        //   ...data.data.data,
        // ]);
        setProperties(data.data.data);
        // setLoading(false);
      },
    });
  }, [
    page,
    mapTrue,
    debouncedSearchCity,
    debouncedSearchFilter,
    debouncedSearchFilterMulti,
    debouncedActiveButton,
    filterBroker,
    listParent,
  ]);

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
    fetchData(
      {
        ...pagination,
        current: page,
      },
      filter
    );
  };
  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
    // setPagination((prev) => ({
    //   ...prev,
    //   current: page,
    // }));
    // fetchData(
    //   {
    //     ...pagination,
    //     current: page,
    //   },
    //   filter
    // );
  };

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.asset_status : null;

    console.log(searchCity, "searchCity");

    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${!mapTrue ? 10000 : pagination ? pagination.pageSize : 10
        }&debouncedSearchCity=${JSON.stringify(debouncedSearchCity)}${path ? `&status=1` : ""
        }&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""
        }&debouncedSearchFilter=${JSON.stringify(
          debouncedSearchFilter
        )}&selectedFiltersMulti=${JSON.stringify(
          debouncedSearchFilterMulti
        )}&make_public=true&broker_asset=${filterBroker}&to_let=${debouncedActiveButton ? debouncedActiveButton : ""
        }&sort=${debouncedSort ? debouncedSort : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        if (status) {
          const listData = data.map((item) => ({ ...item, key: item._id }));

          setList(listData);
          setTotalList(total);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
          !mapTrue && setProperties(data);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const onSearch = (e) => {
    console.log(e);
    setSearchText(e);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const components = {
    body: {
      row: EditableRow,
    },
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  useEffect(() => {
    if (coords) {
      const newLocation = { lat: coords.latitude, lng: coords.longitude };
      setLocation({ lat: coords.latitude, lng: coords.longitude });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [coords]);

  const hanleMapVal = (lat, lng) => {
    console.log(lat, lng);
    setLocation({ lat: lat, lng: lng });
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === "rent" || buttonType === "commercial_rent") {
      setPriceLimit([1, 15000]);
    } else {
      setPriceLimit([1, 10000000]);
    }
  };
  const [activeInputs, setActiveInputs] = useState({});

  const handleFocus = (id) => {
    setActiveInputs((prevActiveInputs) => ({
      ...prevActiveInputs,
      [id]: true,
    }));
  };

  const handleBlur = (id) => {
    setActiveInputs((prevActiveInputs) => ({
      ...prevActiveInputs,
      [id]: false,
    }));
  };
  const handleSelectChange = (value) => {
    setActiveButton(value);
    if (value === "rent") {
      setPriceLimit([1, 15000]);
    } else {
      setPriceLimit([1, 10000000]);
    }
  };
  const handleSetVal = (key, value) => {
    console.log(key, value);
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
    fetchData(pagination, debouncedSearchFilter);
  };

  const setPriceLimitFilter = (value) => {
    console.log(value);
    setPriceLimit(value);
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      // Ensure both min and max values are within an array using optional chaining
      newSelectedFilters["total_price"] = [
        ...(prevSelectedFilters["total_price"] || []),
      ]; // Create array if it doesn't exist
      newSelectedFilters["total_price"] = value; // Update specific index (min or max)

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
    // fetchData(pagination, debouncedSearchFilterMulti);
  };

  const handleSetMultipleSize = (key, value, index) => {
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      // Ensure both min and max values are within an array using optional chaining
      newSelectedFilters[key] = [...(prevSelectedFilters[key] || [])]; // Create array if it doesn't exist
      newSelectedFilters[key][index] = value; // Update specific index (min or max)

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
    // fetchData(pagination, debouncedSearchFilterMulti);
  };

  const handleSetSort = (value) => {
    setSort(value);
  };

  const [activeChecks, setActiveChecks] = useState([]);
  const handleFocusC = (id, checked) => {
    setActiveChecks((prevActiveChecks) => {
      if (checked) {
        return [...prevActiveChecks, id]; // Add id to the array if checked
      } else {
        return prevActiveChecks.filter((item) => item !== id); // Remove id from the array if unchecked
      }
    });
  };
  const handleFocusCA = (id, checked) => {
    setActiveChecksAmenity((prevActiveChecksAmenity) => {
      if (checked) {
        return [...prevActiveChecksAmenity, id]; // Add id to the array if checked
      } else {
        return prevActiveChecksAmenity.filter((item) => item !== id); // Remove id from the array if unchecked
      }
    });
  };

  const handleSetMultiple = (key, value, checked) => {
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };
      console.log(newSelectedFilters);
      if (checked) {
        // If checked, add or append to the property_type array
        newSelectedFilters[key] = prevSelectedFilters[key]
          ? [...prevSelectedFilters[key], value]
          : [value];
      } else {
        // If unchecked, remove the value from property_type array
        if (prevSelectedFilters[key]) {
          const filteredValues = prevSelectedFilters[key].filter(
            (item) => item !== value
          );
          newSelectedFilters[key] = filteredValues;
        } else {
          // If property_type doesn't exist, do nothing
        }
      }

      console.log(newSelectedFilters);
      return newSelectedFilters;
    });
    // fetchData(pagination, debouncedSearchFilterMulti);
  };

  useEffect(() => {
    fetchData({ ...pagination, current: 1 });
    // getFilter();
  }, [
    refresh,
    debouncedSearchText,
    debouncedSearchCity,
    debouncedSearchFilter,
    debouncedSearchFilterMulti,
    startDate,
    debouncedActiveButton,
    endDate,
    debouncedSort,
    filterBroker,
    listParent,
    !mapTrue,
  ]);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [activeFeature, setActiveFeature] = useState(null);
  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (!properties) return;
    if (!properties[0]) return;
    if (!properties[0].latitude) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center:
        cities && cities.length > 0
          ? [properties[0].longitude, properties[0].latitude]
          : [34.7818, 32.0853],
      zoom: cities && cities.length > 0 ? 15 : 10,
    });

    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", () => {
      map.addSource("properties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: properties.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [property?.longitude, property?.latitude],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "properties",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#51bbd6",
          "circle-radius": 20,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "properties",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      let pricePopup = null;
      let detailPopup = null;

      map.on("mouseenter", "unclustered-point", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      map.on("click", "unclustered-point", (e) => {
        if (pricePopup) pricePopup.remove();

        const coordinates = e.features[0].geometry.coordinates.slice();
        const {
          total_price,
          address,
          rooms,
          floor,
          property_type,
          size,
          _id,
          to_let,
        } = e.features[0].properties;

        if (detailPopup) detailPopup.remove();
        detailPopup = new mapboxgl.Popup({ closeButton: true })
          .setLngLat(coordinates)
          .setHTML(
            `<a href="/asset-view/${_id}" target="_blank" style="text-decoration: none; color: inherit;">
              <div>
                <h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
                <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
                  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <span>${address}</span>
                </div>
                <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Rooms" class="img-fluid"/>
                      <span>rooms</span>
                    </div>
                    <span>${rooms}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Floor" class="img-fluid"/>
                      <span>floor</span>
                    </div>
                    <span>${floor}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Property Type" class="img-fluid"/>
                      <span>Property Type</span>
                    </div>
                    <span>${property_type}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Size" class="img-fluid"/>
                      <span>size</span>
                    </div>
                    <span>${size}</span>
                  </li>
                </ul>
              </div>
            </a>`
          )
          .addTo(map);

        setActiveFeature(e.features[0]);
        if (pricePopup) pricePopup.remove();
      });

      // map.on('mouseenter', 'clusters', () => {
      //   map.getCanvas().style.cursor = 'pointer';
      // });
      // map.on('mouseleave', 'clusters', () => {
      //   map.getCanvas().style.cursor = '';
      // });

      // map.on('click', 'clusters', (e) => {
      //   const features = map.queryRenderedFeatures(e.point, {
      //     layers: ['clusters'],
      //   });
      //   const clusterId = features[0].properties.cluster_id;
      //   map
      //     .getSource('properties')
      //     .getClusterExpansionZoom(clusterId, (err, zoom) => {
      //       if (err) return;

      //       map.easeTo({
      //         center: features[0].geometry.coordinates,
      //         zoom,
      //       });
      //     });
      // });
    });

    return () => map.remove();
  }, [properties, listParent]);

  const [selectedRooms, setSelectedRooms] = useState([]);

  const handleFocusW = (id) => {
    setActiveInputs((prev) => ({ ...prev, [id]: true }));
  };

  const handleBlurW = (id) => {
    setActiveInputs((prev) => ({ ...prev, [id]: false }));
  };

  const handleSetValW = (rooms_number) => {
    console.log(rooms_number);
    setSelectedRooms((prev) => {
      console.log(prev.length);
      if (prev.includes(rooms_number)) {
        console.log(rooms_number);
        setSelectedFilter((prevSelectedFilter) => ({
          ...prevSelectedFilter,
          rooms_number: [rooms_number],
        }));
        return [rooms_number];
      }

      if (prev.length === 0) {
        console.log("debt", rooms_number);
        setSelectedFilter((prevSelectedFilter) => ({
          ...prevSelectedFilter,
          rooms_number: [rooms_number],
        }));
        return [rooms_number];
      }

      const min = Math.min(...prev, rooms_number);
      const max = Math.max(...prev, rooms_number);

      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        rooms_number: [min, max],
      }));
      return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    });
    // fetchData(pagination, debouncedSearchFilterMulti);
  };

  const getRangeText = () => {
    if (selectedRooms.length === 0) {
      return "No selection";
    }
    const min = Math.min(...selectedRooms);
    const max = Math.max(...selectedRooms);
    return min === max ? `${min}` : `${min} to ${max}`;
  };

  const handleFilterResert = () => {
    setSelectedRooms([]);
    setActiveInputs({});
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      rooms_number: [],
    }));
    setSearchText("");
    setSelectedFilter({});
    setSelectedFiltersMulti({
      size_in_meters: [null, null],
    });
    setActiveButton("buy");
    setSort(null);
    setAddFiltersVisible(false);
    setSelectedFilters([]);
    setFilterBroker();
    setBrokerActive();
    setSelectedRooms([]);
    setActiveChecks([]);
    fetchData({ ...pagination, current: 1 });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed
  const onHandleClick = (id) => {
    setLoading(true);
    request({
      url: api.edit + "/" + id,
      method: "PUT",
      data: {
        broker_id: true,
      },

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleFilterBroker = (value) => {
    console.log(value);
    if (value === "no_broker") {
      setBrokerActive(value);
      setFilterBroker(false);
    } else if (value === "broker") {
      setBrokerActive(value);
      setFilterBroker(true);
    } else {
      setBrokerActive();
      setFilterBroker();
    }
  };

  const cleanMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return;
    return mobileNumber.replace("-", "");
  };

  // useEffect(() => {
  //   const geocoder = new MapboxGeocoder({
  //     accessToken: mapboxgl.accessToken,
  //     types: "place,postcode,locality,neighborhood",
  //     countries: "IL", // Limit search results to Israel
  //     language: "he",
  //   });

  //   if (geocoderContainerRef.current) {
  //     geocoder.addTo(geocoderContainerRef.current);
  //   }

  //   // Ensure geocoder is initialized before attaching the listener
  //   if (geocoder) {
  //     geocoder.on("result", (event) => {
  //       // Handle the result as needed, for example, store the selected location
  //       console.log("Selected location:", event.result);
  //       onSearch(event.result.place_name);
  //       // setSearchText(event.result.place_name);
  //     });
  //   }
  // }, []);

  const onSearchSet = (e) => {
    setSearchText(e.target.value);
  };

  const handleOptionTypeChange = (value) => {
    console.log(value);

    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };
      console.log(newSelectedFilters);
      if (value.length > 0) {
        newSelectedFilters["property_type"] = value;
      } else {
        delete newSelectedFilters["property_type"];
      }
      setActiveChecks(value);
      console.log(newSelectedFilters);
      return newSelectedFilters;
    });
  };

  const [activeChecksAmenity, setActiveChecksAmenity] = useState([]);

  const handleAmenitiesTypeChange = (value) => {
    console.log(value);

    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };
      console.log(newSelectedFilters);
      if (value.length > 0) {
        newSelectedFilters["amenities"] = value;
      } else {
        delete newSelectedFilters["amenities"];
      }
      setActiveChecksAmenity(value);
      return newSelectedFilters;
    });
  };

  const handleCityUpdate = (value) => {
    console.log(value);
    console.log("onSelect", value);
    setSearchCity(value);
  };

  const columns = [
    {
      title: lang(" Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { multiImage }) => (
        <Image
          width={40}
          src={multiImage.length > 0 ? multiImage[0] : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: lang("City"),
      dataIndex: "location.city",
      key: "location.city",
      width: 200,
      render: (_, { location }) => {
        return location ? location.city : "-";
      },
    },
    {
      title: lang("Address"),
      dataIndex: "location.neighborhood",
      key: "location.neighborhood",
      width: 200,
      render: (_, { location }) => {
        const city = location.city
          ? location.city === "0"
            ? ""
            : location.city
          : "";

        const neighborhood = location.neighborhood
          ? location.neighborhood === "0"
            ? ""
            : location.neighborhood
          : "";
        const street = location.street
          ? location.street === "0"
            ? ""
            : location.street
          : "";
        const street_number = location.street_number
          ? location.street_number === "0"
            ? ""
            : location.street_number
          : "";

        return `${city}${" "}${neighborhood}${" "}${street}${" "}${street_number}`;
      },
    },
    {
      title: lang("Price"),
      dataIndex: "total_price",
      key: "total_price",
      width: 200,
      render: (_, { total_price }) => {
        return total_price ? new Intl.NumberFormat().format(total_price) : "-";
      },
      sorter: (a, b) => {
        if (a.total_price === undefined) return -1;
        if (b.total_price === undefined) return 1;
        return a.total_price - b.total_price;
      },
    },
    {
      title: lang("Room"),
      dataIndex: "rooms_number",
      key: "rooms_number",
      width: 200,
      render: (_, { rooms_number }) => {
        return rooms_number ? rooms_number : "-";
      },
    },
    {
      title: lang("Size (Meter.)"),
      dataIndex: "size_in_meters",
      key: "size_in_meters",
      width: 200,
      render: (_, { size_in_meters }) => {
        return size_in_meters
          ? new Intl.NumberFormat().format(size_in_meters)
          : "-";
      },
      sorter: (a, b) => {
        if (a.size_in_meters === undefined) return -1;
        if (b.size_in_meters === undefined) return 1;
        return a.size_in_meters - b.size_in_meters;
      },
    },
    {
      title: lang("From Broker"),
      dataIndex: "fromBroker",
      key: "fromBroker",
      width: 200,
      render: (_, { fromBroker }) => {
        return fromBroker ? "Yes" : "No";
      },
    },
    {
      title: lang("Type"),
      dataIndex: "property_type",
      key: "property_type",
      width: 200,
      render: (_, { property_type }) => {
        return property_type ? property_type : "-";
      },
    },

    {
      title: lang("Action"),
      key: "_id",
      dataIndex: "_id",
      render: (
        _,
        { make_public, _id, broker_id, country_code, mobile_number, signed_doc }
      ) => {
        return (
          <div className="d-flex justify-content-start">
            <Tooltip
              title={lang("Whatsapp")}
              color={"purple"}
              key={"whatapp" + _id}
            >
              <a
                href={"https://wa.me/" + cleanMobileNumber(mobile_number)}
                className="whatsapp-cls"
              // target="_blank"
              >
                <WhatsAppIcon />
              </a>
            </Tooltip>
            <Tooltip title={"View"} color={"purple"} key={"View"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  navigate(`/asset-view/${_id}`);
                  setSelected({ recordId: _id });
                  setDetailsVisible(true);
                }}
              >
                <RemoveRedEyeOutlinedIcon />
              </Button>
            </Tooltip>

            {!broker_id.includes(userProfile._id) && (
              <Tooltip>
                <Button
                  onClick={() => onHandleClick(_id)}
                  className="edit-cls btnStyle primary_btn"
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="spiner-box">
          <Spin />
        </div>
      ) : (
        <>
          <Row>
            <div className="main-hh-headding">
              <div className="headding-cc">
                {lang('Public Asset')}
                {/* Public Asset */}
                {/* My {lang("Asset") + " " + lang("List")} */}
              </div>
              <Breadcrumb className="bread-menu">
                <Breadcrumb.Item className="bread-items" href="#">
                  Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="bread-items" href="#">
                  Public Asset
                  {/* {lang("Asset") + " " + lang("List")} */}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Row>
          <Card>
            <Row>
              <Col xs={24} xl={24}>
                <div className="searchpanel-main">
                  <div className="main-second-div-for-screen-out">
                    <Dropdown>
                      <Dropdown.Toggle className="panel-button-drp short-by-cls ">
                        <div className="panel-header">
                          <Button className="filter-bt">
                            <img src={ScreenOutIcon} />
                            <span>לסנן</span>
                          </Button>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        id="screen-filter"
                        className="sub-panel-items"
                      >
                        <div className="short-by-cls ">
                          <Row gutter={[24, 24]} className="flex-wrap">
                            <Col span={24} lg={12}>
                              <div className="toglle-section">
                                <div className="prt-headding">
                                  <label>{lang('For rent/sale')}</label>
                                </div>
                                <div className="toggle-filter">
                                  <button
                                    className={`rent-btns ${activeButton === "rent" ? "active" : ""
                                      }`}
                                    onClick={() => handleButtonClick("rent")}
                                  >
                                    <img src={ForrentIcon} />
                                    {lang('For Rent')}
                                  </button>
                                  <button
                                    className={`rent-btns ${activeButton === "buy" ? "active" : ""
                                      }`}
                                    onClick={() => handleButtonClick("buy")}
                                  >
                                    <img src={ForsaleIcon} />
                                    {lang('For Sale')}
                                  </button>
                                  <button
                                    className={`rent-btns ${activeButton === "commercial_rent" ? "active" : ""
                                      }`}
                                    onClick={() => handleButtonClick("commercial_rent")}
                                  >
                                    <img src={ForsaleIcon} />
                                    {lang('For Commercial Rent')}
                                  </button>

                                  <button
                                    className={`rent-btns ${activeButton === "commercial_buy" ? "active" : ""
                                      }`}
                                    onClick={() => handleButtonClick("commercial_buy")}
                                  >
                                    <img src={ForsaleIcon} />
                                    {lang('For Commercial Buy')}
                                  </button>
                                </div>
                              </div>
                            </Col>
                            <Col span={24} lg={12}>
                              <div className="prt-headding">
                                <label>Property Type</label>
                              </div>
                              <div className="checkbox-menu-items">
                                <Select
                                  className="select-proprty"
                                  mode="multiple"
                                  placeholder="Select property types"
                                  defaultValue={
                                    activeChecks ? activeChecks : []
                                  }
                                  onChange={handleOptionTypeChange}
                                  options={options12.map((option) => ({
                                    label: option.text,
                                    value: option.value,
                                  }))}
                                ></Select>
                              </div>
                            </Col>

                            <Col span={24} lg={12}>
                              <div className="prt-headding">
                                <label>Price</label>
                              </div>
                              <div className="price-range">
                                <Slider
                                  className="slaider-row"
                                  min={1}
                                  max={10000000}
                                  range={{
                                    draggableTrack: true,
                                  }}
                                  value={priceLimit}
                                  onChange={setPriceLimitFilter} // use onChange instead of onchange
                                />
                                <div className="count-box">
                                  <div className="count-detail">
                                    <span>
                                      {priceLimit
                                        ? new Intl.NumberFormat().format(
                                          priceLimit[0]
                                        )
                                        : "0"}
                                      {"  "}
                                      ₪
                                    </span>
                                    <span>
                                      {priceLimit
                                        ? new Intl.NumberFormat().format(
                                          priceLimit[1]
                                        )
                                        : "100,000"}
                                      {"  "}
                                      ₪
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col span={24} lg={12}>
                              <div className="prt-headding">
                                <label>Area in square meters</label>
                              </div>
                              <div className="two-inn-filter">
                                <Input
                                  placeholder="maximum"
                                  onChange={(e) =>
                                    handleSetMultipleSize(
                                      "size_in_meters",
                                      e.target.value,
                                      0
                                    )
                                  }
                                />
                                <Input
                                  placeholder="minimum"
                                  onChange={(e) =>
                                    handleSetMultipleSize(
                                      "size_in_meters",
                                      e.target.value,
                                      1
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={24} lg={12}>
                              <div className="toglle-section">
                                <div className="prt-headding">
                                  <label>{lang("For Broker/Non-Broker")}</label>
                                </div>
                                <div className="panel-box-coll">
                                  <div className="toggle-filter">
                                    <button
                                      className={`rent-btns ${brokerActive === "broker"
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() =>
                                        handleFilterBroker("broker")
                                      }
                                    >
                                      {lang("For Broker")}
                                    </button>
                                    <button
                                      className={`rent-btns ${brokerActive === "no_broker"
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() =>
                                        handleFilterBroker("no_broker")
                                      }
                                    >
                                      {lang("For Non-Broker")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col span={24} lg={12}>
                              <div className="prt-headding"></div>
                              <h6 className="">Amenities</h6>
                              <div className="checkbox-menu-items">
                                <div className="pro-type">
                                  {optionsAmeni.map((option) => (
                                    <Checkbox
                                      value={option.value}
                                      checked={activeChecksAmenity.includes(
                                        option.value
                                      )}
                                      onChange={(e) => {
                                        handleSetMultiple(
                                          "amenities",
                                          e.target.value,
                                          e.target.checked
                                        );
                                        handleFocusCA(
                                          e.target.value,
                                          e.target.checked
                                        );
                                      }}
                                    >
                                      {option.text}
                                    </Checkbox>
                                  ))}

                                  {/* <Select
                                    className="select-amenities"
                                    mode="multiple"
                                    placeholder="Select Amenities"
                                    defaultValue={
                                      activeChecksAmenity ? activeChecksAmenity : []
                                    }
                                    onChange={handleAmenitiesTypeChange}
                                    options={optionsAmeni.map((option) => ({
                                      label: option.text,
                                      value: option.value,
                                    }))}
                                  ></Select> */}
                                </div>
                              </div>
                            </Col>
                            <Col span={24} lg={12}>
                              <div className="prt-headding">
                                <label>Rooms</label>
                              </div>
                              <div
                                className="radio-option bedrooms-list"
                                data-bdnum="1"
                                id="bedrooms-list"
                              >
                                {[1, 2, 3, 4, 5, 6].map((num) => (
                                  <button
                                    key={num}
                                    id={`minPrice${num}`}
                                    className={`rooms-input ${selectedRooms.includes(num)
                                      ? "active"
                                      : ""
                                      }`}
                                    name="rooms_number"
                                    onFocus={() =>
                                      handleFocusW(`minPrice${num}`)
                                    }
                                    onBlur={() => handleBlurW(`minPrice${num}`)}
                                    onClick={() => handleSetValW(num)}
                                  >
                                    {num === 6 ? "6+" : num}
                                  </button>
                                ))}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="main-select-outer">
                    <Select
                      className="searchselectmain"
                      mode="multiple"
                      placeholder="Select Cities & Neighborhood"
                      defaultValue={searchCity ? searchCity : []}
                      onChange={handleCityUpdate}
                      onSearch={handleSearch}
                      options={
                        sortedcities?.length > 0 &&
                        sortedcities.map((option) => ({
                          label: (
                            <span>
                              <EnvironmentOutlined style={{ marginRight: 8 }} />
                              {option.city}
                              {option.neighborhood
                                ? ", " + option.neighborhood
                                : ""}
                              {option.neighborhood && option.street
                                ? ", " + option.street + "  רְחוֹב"
                                : option.neighborhood
                                  ? "  שכונה"
                                  : "  עיר"}
                            </span>
                          ),
                          value:
                            option.city +
                            (option.neighborhood
                              ? "," + option.neighborhood
                              : "") +
                            (option.neighborhood && option.street
                              ? ", " + option.street
                              : ""),
                        }))
                      }
                    ></Select>
                    <Button class="search-btns-select">
                      <span role="img" aria-label="search" class=" ">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="search"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                        </svg>
                      </span>
                    </Button>
                  </div>

                  {/* </AutoComplete> */}
                </div>
              </Col>
            </Row>
            {/* <div className="d-md-none d-block menu-ftr">
              <Button className=" filter-btn" onClick={() => setShow(!show)}>
                <PlusOutlined /> Fillter
              </Button>
            </div> */}
            <div className="d-none d-md-block ">
              <div className="icon-bar header-icon-bar">
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button
                        className="icon-item"
                        onClick={() => handleFilterResert()}
                      >
                        <img src={CleanUpIcon} />
                        <span>נקה</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={BrokerIcons} />
                        <span>מתיווך/ לא מתיווך</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="panel-box-coll">
                      <div className="toggle-filter">
                        <button
                          className={`rent-btns ${brokerActive === "broker" ? "active" : ""
                            }`}
                          onClick={() => handleFilterBroker("broker")}
                        >
                          {lang("For Broker")}
                        </button>
                        <button
                          className={`rent-btns ${brokerActive === "no_broker" ? "active" : ""
                            }`}
                          onClick={() => handleFilterBroker("no_broker")}
                        >
                          {lang("For Non-Broker")}
                        </button>
                      </div>
                      <div className="drp-dawn-btn">
                        <button
                          className="cl-btns"
                          onClick={() => {
                            handleFilterBroker();
                          }}
                        >
                          {lang("Clean Up")}
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={RoomBed} />
                        <span>חדרים</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="panel-box-coll">
                      <h6 className="">rooms</h6>
                      <div
                        className="radio-option bedrooms-list"
                        data-bdnum="1"
                        id="bedrooms-list"
                      >
                        {[1, 2, 3, 4, 5, 6].map((num) => (
                          <button
                            key={num}
                            id={`minPrice${num}`}
                            className={`rooms-input ${selectedRooms.includes(num) ? "active" : ""
                              }`}
                            name="rooms_number"
                            onFocus={() => handleFocusW(`minPrice${num}`)}
                            onBlur={() => handleBlurW(`minPrice${num}`)}
                            onClick={() => handleSetValW(num)}
                          >
                            {num === 6 ? "6+" : num}
                          </button>
                        ))}
                      </div>
                      <div className="drp-dawn-btn">
                        <button
                          className="cl-btns"
                          onClick={() => {
                            setSelectedRooms([]);
                            setActiveInputs({});
                            setSelectedFilter((prevSelectedFilter) => ({
                              ...prevSelectedFilter,
                              rooms_number: [],
                            }));
                          }}
                        >
                          {lang("Clean Up")}
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={PropertyType} />
                        <span>סוג הנכס</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="panel-box-coll">
                      <h6 className="">Property Type</h6>
                      <div className="checkbox-menu-items">
                        <div className="pro-type">
                          <Checkbox
                            value="דירה"
                            checked={activeChecks.includes("דירה")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Apartment
                          </Checkbox>
                          <Checkbox
                            value="מִסְחָרִי מְקַרקְעִין"
                            checked={activeChecks.includes(
                              "מִסְחָרִי מְקַרקְעִין"
                            )}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Commercial Real Estate
                          </Checkbox>

                          <Checkbox
                            value="קוטג'"
                            checked={activeChecks?.includes("קוטג'")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            House / Cottage
                          </Checkbox>
                          <Checkbox
                            value="דירת גן"
                            checked={activeChecks?.includes("דירת גן")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Garden Apartment
                          </Checkbox>
                          <Checkbox
                            value="דו משפחתי"
                            checked={activeChecks?.includes("דו משפחתי")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Townhouse
                          </Checkbox>
                          <Checkbox
                            value="גג/ פנטהאוז"
                            checked={activeChecks?.includes("גג/ פנטהאוז")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Rooftop / Penthouse
                          </Checkbox>
                          <Checkbox
                            value="פנטהאוז"
                            checked={activeChecks?.includes("פנטהאוז")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Penthouse
                          </Checkbox>
                          <Checkbox
                            value="מיני פנטהאוז"
                            checked={activeChecks?.includes("מיני פנטהאוז")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Mini Penthouse
                          </Checkbox>
                          <Checkbox
                            value="דירת גג"
                            checked={activeChecks?.includes("דירת גג")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Rooftop Apartment
                          </Checkbox>
                          <Checkbox
                            value="סטודיו/ לופט"
                            checked={activeChecks?.includes("סטודיו/ לופט")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Studio / Loft
                          </Checkbox>
                          <Checkbox
                            value="דופלקס"
                            checked={activeChecks?.includes("דופלקס")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Duplex
                          </Checkbox>
                          <Checkbox
                            value="טריפלקס"
                            checked={activeChecks?.includes("טריפלקס")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Triplex
                          </Checkbox>
                          <Checkbox
                            value="יחידת דיור"
                            checked={activeChecks?.includes("יחידת דיור")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Apartment Unit
                          </Checkbox>
                          <Checkbox
                            value="מגרשים"
                            checked={activeChecks?.includes("מגרשים")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Plots
                          </Checkbox>
                          <Checkbox
                            value="משק חקלאי/ נחלה"
                            checked={activeChecks?.includes("משק חקלאי/ נחלה")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Farmhouse / Homestead
                          </Checkbox>
                          <Checkbox
                            value="בניין"
                            checked={activeChecks?.includes("בניין")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Building
                          </Checkbox>
                          <Checkbox
                            value="דירה מחולקת"
                            checked={activeChecks?.includes("דירה מחולקת")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Divided Apartment
                          </Checkbox>
                          <Checkbox
                            value="דירת מרתף"
                            checked={activeChecks?.includes("דירת מרתף")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Basement Apartment
                          </Checkbox>
                          <Checkbox
                            value="מרתף/ פרטר"
                            checked={activeChecks?.includes("מרתף/ פרטר")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Basement / Parter
                          </Checkbox>
                          <Checkbox
                            value="בניין מגורים"
                            checked={activeChecks?.includes("בניין מגורים")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Residential Building
                          </Checkbox>
                          <Checkbox
                            value="דירת נופש"
                            checked={activeChecks?.includes("דירת נופש")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Vacation Apartment
                          </Checkbox>
                          <Checkbox
                            value="חניה"
                            checked={activeChecks?.includes("חניה")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Parking
                          </Checkbox>
                          <Checkbox
                            value="מחסן"
                            checked={activeChecks?.includes("מחסן")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Storage
                          </Checkbox>
                          <Checkbox
                            value="אחר"
                            checked={activeChecks?.includes("אחר")}
                            onChange={(e) => {
                              handleSetMultiple(
                                "property_type",
                                e.target.value,
                                e.target.checked
                              );
                              handleFocusC(e.target.value, e.target.checked);
                            }}
                          >
                            Other
                          </Checkbox>
                        </div>
                      </div>
                      <div className="drp-dawn-btn">
                        {/* <button className="cho-btns">Choose</button> */}
                        <button
                          className="cl-btns"
                          onClick={() => {
                            setSelectedFiltersMulti((prevSelectedFilters) => {
                              const newSelectedFilters = {
                                ...prevSelectedFilters,
                              };
                              delete newSelectedFilters["property_type"];
                              // newSelectedFilters["property_type"] = [];
                              return newSelectedFilters;
                            });
                            setActiveChecks([]);
                          }}
                        >
                          {lang("Clean Up")}
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={SquareIcons} />
                        <span>שטח במ"ר</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="panel-box-coll">
                      <h6 className=" ">Area in square meters</h6>
                      <div className="two-columns">
                        <Input
                          id="minSize"
                          name="size_in_meters"
                          placeholder="minimum"
                          onChange={(e) =>
                            handleSetMultipleSize(
                              "size_in_meters",
                              e.target.value,
                              0
                            )
                          }
                        />
                        <Input
                          id="maxSize"
                          name="size_in_meters"
                          placeholder="maximum"
                          onChange={(e) =>
                            handleSetMultipleSize(
                              "size_in_meters",
                              e.target.value,
                              1
                            )
                          }
                        />
                      </div>
                      <div className="drp-dawn-btn">
                        {/* <button className="cho-btns">Choose</button> */}
                        <button
                          className="cl-btns"
                          onClick={() => {
                            setSelectedFiltersMulti((prevSelectedFilters) => {
                              const newSelectedFilters = {
                                ...prevSelectedFilters,
                              };
                              newSelectedFilters["size_in_meters"] = [
                                null,
                                null,
                              ];
                              return newSelectedFilters;
                            });
                          }}
                        >
                          {lang("Clean Up")}
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={PriceIcon} />
                        <span>מחיר</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div>
                      <h6 className="">Price</h6>
                      <div className="price-range">
                        <Slider
                          className="slaider-row"
                          min={1}
                          max={
                            debouncedActiveButton === "buy" ? 10000000 : 15000
                          }
                          range={{
                            draggableTrack: true,
                          }}
                          tooltip={{ open: false }}
                          value={priceLimit}
                          onChange={setPriceLimitFilter} // use onChange instead of onchange
                        />
                        <div className="count-box">
                          <div className="count-detail">
                            <span>
                              {priceLimit
                                ? new Intl.NumberFormat().format(priceLimit[0])
                                : "0"}
                              {"  "}₪
                            </span>
                            <span>
                              +{priceLimit
                                ? new Intl.NumberFormat().format(priceLimit[1])
                                : "100,000"}
                              {"  "}₪
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="drp-dawn-btn">
                        {/* <button className="cho-btns">Choose</button> */}
                        <button
                          className="cl-btns"
                          onClick={() => {
                            {
                              debouncedActiveButton === "buy"
                                ? setPriceLimit([0, 10000000])
                                : setPriceLimit([0, 15000]);
                            }

                            setSelectedFiltersMulti((prevSelectedFilters) => {
                              const newSelectedFilters = {
                                ...prevSelectedFilters,
                              };
                              // newSelectedFilters["total_price"] = [];
                              delete newSelectedFilters["total_price"];
                              return newSelectedFilters;
                            });
                          }}
                        >
                          {lang("Clean Up")}
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp">
                    <div className="panel-header">
                      <Button className="icon-item">
                        <img src={SortByIcon} />
                        <span>מיין לפי</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="short-by-cls">
                      {/* <ul>
                    <li>Date: from old to new</li>
                    <li>Date: from new to old</li>
                    <li>Price: from low to high</li>
                    <li>Price: from high to low</li>
                  </ul> */}
                      <Select
                        placeholder="Select Sortable Value"
                        onChange={(values) => handleSetSort(values)}
                      >
                        <Select.Option value="old_to_new">
                          Date: from Old to New
                        </Select.Option>
                        <Select.Option value="new_to_old">
                          Date: from New to Old
                        </Select.Option>
                        <Select.Option value="low_to_high">
                          Price: from Low to High
                        </Select.Option>
                        <Select.Option value="high_to_low">
                          Price: from High to Low
                        </Select.Option>
                      </Select>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp short-by-cls ">
                    <div className="panel-header">
                      <Button className="filter-bt">
                        <img src={ScreenOutIcon} />
                        <span>מסך החוצה</span>
                      </Button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu id="screen-filter" className="sub-panel-items">
                    <div className="short-by-cls ">
                      <Row gutter={[24, 24]} className="flex-wrap">
                        <Col span={24} lg={12}>
                          <div className="toglle-section">
                            <div className="prt-headding">
                              <label>{lang('For rent/sale')}</label>
                            </div>
                            <div className="toggle-filter">
                              <button
                                className={`rent-btns ${activeButton === "rent" ? "active" : ""
                                  }`}
                                onClick={() => handleButtonClick("rent")}
                              >
                                <img src={ForrentIcon} />
                                {lang('For Rent')}
                              </button>
                              <button
                                className={`rent-btns ${activeButton === "buy" ? "active" : ""
                                  }`}
                                onClick={() => handleButtonClick("buy")}
                              >
                                <img src={ForsaleIcon} />
                                {lang('For Sale')}
                              </button>
                              <button
                                className={`rent-btns ${activeButton === "commercial_rent" ? "active" : ""
                                  }`}
                                onClick={() => handleButtonClick("commercial_rent")}
                              >
                                <img src={ForsaleIcon} />
                                {lang('For Commercial Rent')}
                              </button>

                              <button
                                className={`rent-btns ${activeButton === "commercial_buy" ? "active" : ""
                                  }`}
                                onClick={() => handleButtonClick("commercial_buy")}
                              >
                                <img src={ForsaleIcon} />
                                {lang('For Commercial Buy')}
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col span={24} lg={12}>
                          <div className="prt-headding">
                            <label>Property Type</label>
                          </div>
                          <div className="checkbox-menu-items">
                            <Select
                              className="select-proprty"
                              mode="multiple"
                              placeholder="Select property types"
                              defaultValue={activeChecks ? activeChecks : []}
                              onChange={handleOptionTypeChange}
                              options={options12.map((option) => ({
                                label: option.text,
                                value: option.value,
                              }))}
                            ></Select>
                          </div>
                        </Col>

                        <Col span={24} lg={12}>
                          <div className="prt-headding">
                            <label>Price</label>
                          </div>
                          <div className="price-range">
                            <Slider
                              className="slaider-row"
                              min={1}
                              max={10000000}
                              range={{
                                draggableTrack: true,
                              }}
                              value={priceLimit}
                              onChange={setPriceLimitFilter} // use onChange instead of onchange
                            />
                            <div className="count-box">
                              <div className="count-detail">
                                <span>
                                  {priceLimit
                                    ? new Intl.NumberFormat().format(
                                      priceLimit[0]
                                    )
                                    : "0"}
                                  {"  "}
                                  ₪
                                </span>
                                <span>
                                  +{priceLimit
                                    ? new Intl.NumberFormat().format(
                                      priceLimit[1]
                                    )
                                    : "100,000"}
                                  {"  "}
                                  ₪
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col span={24} lg={12}>
                          <div className="prt-headding">
                            <label>Area in square meters</label>
                          </div>
                          <div className="two-inn-filter">
                            <Input
                              placeholder="maximum"
                              onChange={(e) =>
                                handleSetMultipleSize(
                                  "size_in_meters",
                                  e.target.value,
                                  0
                                )
                              }
                            />
                            <Input
                              placeholder="minimum"
                              onChange={(e) =>
                                handleSetMultipleSize(
                                  "size_in_meters",
                                  e.target.value,
                                  1
                                )
                              }
                            />
                          </div>
                        </Col>
                        <Col span={24} lg={12}>
                          <div className="toglle-section">
                            <div className="prt-headding">
                              <label>{lang("For Broker/Non-Broker")}</label>
                            </div>
                            <div className="panel-box-coll">
                              <div className="toggle-filter">
                                <button
                                  className={`rent-btns ${brokerActive === "broker" ? "active" : ""
                                    }`}
                                  onClick={() => handleFilterBroker("broker")}
                                >
                                  {lang("For Broker")}
                                </button>
                                <button
                                  className={`rent-btns ${brokerActive === "no_broker" ? "active" : ""
                                    }`}
                                  onClick={() =>
                                    handleFilterBroker("no_broker")
                                  }
                                >
                                  {lang("For Non-Broker")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col span={24} lg={12}>
                          <div className="prt-headding"></div>
                          <h6 className="">Amenities</h6>
                          <div className="checkbox-menu-items">
                            <div className="pro-type">
                              {optionsAmeni.map((option) => (
                                <Checkbox
                                  value={option.value}
                                  checked={activeChecksAmenity.includes(
                                    option.value
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "amenities",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusCA(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  {option.text}
                                </Checkbox>
                              ))}

                              {/* <Select
                                className="select-amenities"
                                mode="multiple"
                                placeholder="Select Amenities"
                                defaultValue={
                                  activeChecksAmenity ? activeChecksAmenity : []
                                }
                                onChange={handleAmenitiesTypeChange}
                                options={optionsAmeni.map((option) => ({
                                  label: option.text,
                                  value: option.value,
                                }))}
                              ></Select> */}
                            </div>
                          </div>
                        </Col>
                        <Col span={24} lg={12}>
                          <div className="prt-headding">
                            <label>Rooms</label>
                          </div>
                          <div
                            className="radio-option bedrooms-list"
                            data-bdnum="1"
                            id="bedrooms-list"
                          >
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                              <button
                                key={num}
                                id={`minPrice${num}`}
                                className={`rooms-input ${selectedRooms.includes(num) ? "active" : ""
                                  }`}
                                name="rooms_number"
                                onFocus={() => handleFocusW(`minPrice${num}`)}
                                onBlur={() => handleBlurW(`minPrice${num}`)}
                                onClick={() => handleSetValW(num)}
                              >
                                {num === 6 ? "6+" : num}
                              </button>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp no-menu">
                    <div className="publick-asset">
                      <button className="rent-btns">
                        <img src={ForsaleIcon} />
                        Select Option
                      </button>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sub-panel-items">
                    <div className="publick-asset rn-selected">
                      <button
                        className={`rent-btns ${activeButton === "buy" ? "active" : ""}`}
                        onClick={() => handleButtonClick("buy")}
                      >
                        <img src={ForsaleIcon} />
                        למכירה {/* For Sale */}
                      </button>
                    </div>

                    <div className="publick-asset rn-selected">
                      <button
                        className={`rent-btns ${activeButton === "rent" ? "active" : ""}`}
                        onClick={() => handleButtonClick("rent")}
                      >
                        <img src={ForrentIcon} />
                        להשכרה {/* For Rent */}
                      </button>
                    </div>

                    <div className="publick-asset rn-selected">
                      <button
                        className={`rent-btns ${activeButton === "commercial_buy" ? "active" : ""}`}
                        onClick={() => handleButtonClick("commercial_buy")}
                      >
                        <img src={ForsaleIcon} />
                        {lang('commercial_buy')} {/* Commercial Buy */}
                      </button>
                    </div>

                    <div className="publick-asset rn-selected">
                      <button
                        className={`rent-btns ${activeButton === "commercial_rent" ? "active" : ""}`}
                        onClick={() => handleButtonClick("commercial_rent")}
                      >
                        <img src={ForrentIcon} />
                        {lang('commercial_rent')} {/* Commercial Rent */}
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>



                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp no-menu">
                    <div className="publick-asset">
                      <Switch
                        checkedChildren="מפה"
                        unCheckedChildren="רשימה"
                        checked={mapTrue}
                        onClick={(mapTrue) => handleChangeMap(mapTrue)}
                        className="maps-changes-check"
                      />
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="panel-button-drp no-menu">
                    <div className="lead-clint-drop">
                      <Switch
                        checkedChildren={lang("Card")}
                        unCheckedChildren={lang("List")}
                        checked={listParent}
                        onClick={(listParent) => onChangeParentList(listParent)}
                        className="main-mecu-switch"
                      />
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </div>
            <div className="">
              {show && (
                <>
                  <div className="add-myfilter-inn d-md-none">
                    <div className="icon-bar header-menubar d-md-none">
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button
                              className="icon-item"
                              onClick={() => handleFilterResert()}
                            >
                              <img src={CleanUpIcon} />
                              <span>נקה</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={BrokerIcons} />
                              <span>בְּרוֹקֶר/לא בְּרוֹקֶר</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div className="panel-box-coll">
                            <div className="toggle-filter">
                              <button
                                className={`rent-btns ${brokerActive === "broker" ? "active" : ""}`}
                                onClick={() => handleFilterBroker("broker")}
                              >
                                {lang("For Broker")}
                              </button>
                              <button
                                className={`rent-btns ${brokerActive === "no_broker" ? "active" : ""}`}
                                onClick={() => handleFilterBroker("no_broker")}
                              >
                                {lang("For Non-Broker")}
                              </button>
                            </div>
                            <div className="drp-dawn-btn">
                              <button
                                className="cl-btns"
                                onClick={() => {
                                  handleFilterBroker();
                                }}
                              >
                                {lang("Clean Up")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>

                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={RoomBed} />
                              <span>חדרים</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div className="panel-box-coll">
                            <h6 className="">{lang("rooms")}</h6>
                            <div
                              className="radio-option bedrooms-list"
                              data-bdnum="1"
                              id="bedrooms-list"
                            >
                              {[1, 2, 3, 4, 5, 6].map((num) => (
                                <button
                                  key={num}
                                  id={`minPrice${num}`}
                                  className={`rooms-input ${selectedRooms.includes(num) ? "active" : ""}`}
                                  name="rooms_number"
                                  onFocus={() => handleFocusW(`minPrice${num}`)}
                                  onBlur={() => handleBlurW(`minPrice${num}`)}
                                  onClick={() => handleSetValW(num)}
                                >
                                  {num === 6 ? lang("6+") : num}
                                </button>
                              ))}
                            </div>
                            <div className="drp-dawn-btn">
                              <button
                                className="cl-btns"
                                onClick={() => {
                                  setSelectedRooms([]);
                                  setActiveInputs({});
                                  setSelectedFilter((prevSelectedFilter) => ({
                                    ...prevSelectedFilter,
                                    rooms_number: [],
                                  }));
                                }}
                              >
                                {lang("Clean Up")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>

                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={PropertyType} />
                              <span>סוג הנכס</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div className="panel-box-coll">
                            <h6 className="">Property Type</h6>
                            <div className="checkbox-menu-items">
                              <div className="pro-type">
                                <Checkbox
                                  value="דירה"
                                  checked={activeChecks.includes("דירה")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Apartment
                                </Checkbox>

                                <Checkbox
                                  value="מִסְחָרִי מְקַרקְעִין"
                                  checked={activeChecks.includes(
                                    "מִסְחָרִי מְקַרקְעִין"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Commercial Real Estate
                                </Checkbox>

                                <Checkbox
                                  value="קוטג'"
                                  checked={activeChecks?.includes("קוטג'")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  House / Cottage
                                </Checkbox>
                                <Checkbox
                                  value="דירת גן"
                                  checked={activeChecks?.includes("דירת גן")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Garden Apartment
                                </Checkbox>
                                <Checkbox
                                  value="דו משפחתי"
                                  checked={activeChecks?.includes("דו משפחתי")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Townhouse
                                </Checkbox>
                                <Checkbox
                                  value="גג/ פנטהאוז"
                                  checked={activeChecks?.includes(
                                    "גג/ פנטהאוז"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Rooftop / Penthouse
                                </Checkbox>
                                <Checkbox
                                  value="פנטהאוז"
                                  checked={activeChecks?.includes("פנטהאוז")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Penthouse
                                </Checkbox>
                                <Checkbox
                                  value="מיני פנטהאוז"
                                  checked={activeChecks?.includes(
                                    "מיני פנטהאוז"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Mini Penthouse
                                </Checkbox>
                                <Checkbox
                                  value="דירת גג"
                                  checked={activeChecks?.includes("דירת גג")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Rooftop Apartment
                                </Checkbox>
                                <Checkbox
                                  value="סטודיו/ לופט"
                                  checked={activeChecks?.includes(
                                    "סטודיו/ לופט"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Studio / Loft
                                </Checkbox>
                                <Checkbox
                                  value="דופלקס"
                                  checked={activeChecks?.includes("דופלקס")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Duplex
                                </Checkbox>
                                <Checkbox
                                  value="טריפלקס"
                                  checked={activeChecks?.includes("טריפלקס")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Triplex
                                </Checkbox>
                                <Checkbox
                                  value="יחידת דיור"
                                  checked={activeChecks?.includes("יחידת דיור")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Apartment Unit
                                </Checkbox>
                                <Checkbox
                                  value="מגרשים"
                                  checked={activeChecks?.includes("מגרשים")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Plots
                                </Checkbox>
                                <Checkbox
                                  value="משק חקלאי/ נחלה"
                                  checked={activeChecks?.includes(
                                    "משק חקלאי/ נחלה"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Farmhouse / Homestead
                                </Checkbox>
                                <Checkbox
                                  value="בניין"
                                  checked={activeChecks?.includes("בניין")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Building
                                </Checkbox>
                                <Checkbox
                                  value="דירה מחולקת"
                                  checked={activeChecks?.includes(
                                    "דירה מחולקת"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Divided Apartment
                                </Checkbox>
                                <Checkbox
                                  value="דירת מרתף"
                                  checked={activeChecks?.includes("דירת מרתף")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Basement Apartment
                                </Checkbox>
                                <Checkbox
                                  value="מרתף/ פרטר"
                                  checked={activeChecks?.includes("מרתף/ פרטר")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Basement / Parter
                                </Checkbox>
                                <Checkbox
                                  value="בניין מגורים"
                                  checked={activeChecks?.includes(
                                    "בניין מגורים"
                                  )}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Residential Building
                                </Checkbox>
                                <Checkbox
                                  value="דירת נופש"
                                  checked={activeChecks?.includes("דירת נופש")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Vacation Apartment
                                </Checkbox>
                                <Checkbox
                                  value="חניה"
                                  checked={activeChecks?.includes("חניה")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Parking
                                </Checkbox>
                                <Checkbox
                                  value="מחסן"
                                  checked={activeChecks?.includes("מחסן")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Storage
                                </Checkbox>
                                <Checkbox
                                  value="אחר"
                                  checked={activeChecks?.includes("אחר")}
                                  onChange={(e) => {
                                    handleSetMultiple(
                                      "property_type",
                                      e.target.value,
                                      e.target.checked
                                    );
                                    handleFocusC(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Other
                                </Checkbox>
                              </div>
                            </div>
                            <div className="drp-dawn-btn">
                              {/* <button className="cho-btns">Choose</button> */}
                              <button
                                className="cl-btns"
                                onClick={() => {
                                  setSelectedFiltersMulti(
                                    (prevSelectedFilters) => {
                                      const newSelectedFilters = {
                                        ...prevSelectedFilters,
                                      };
                                      delete newSelectedFilters[
                                        "property_type"
                                      ];
                                      // newSelectedFilters["property_type"] = [];
                                      return newSelectedFilters;
                                    }
                                  );
                                  setActiveChecks([]);
                                }}
                              >
                                {lang("Clean Up")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={SquareIcons} />
                              <span>שטח במ"ר</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div className="panel-box-coll">
                            <h6 className=" ">Area in square meters</h6>
                            <div className="two-columns">
                              <Input
                                id="minSize"
                                name="size_in_meters"
                                placeholder="minimum"
                                onChange={(e) =>
                                  handleSetMultipleSize(
                                    "size_in_meters",
                                    e.target.value,
                                    0
                                  )
                                }
                              />
                              <Input
                                id="maxSize"
                                name="size_in_meters"
                                placeholder="maximum"
                                onChange={(e) =>
                                  handleSetMultipleSize(
                                    "size_in_meters",
                                    e.target.value,
                                    1
                                  )
                                }
                              />
                            </div>
                            <div className="drp-dawn-btn">
                              {/* <button className="cho-btns">Choose</button> */}
                              <button
                                className="cl-btns"
                                onClick={() => {
                                  setSelectedFiltersMulti(
                                    (prevSelectedFilters) => {
                                      const newSelectedFilters = {
                                        ...prevSelectedFilters,
                                      };
                                      newSelectedFilters["size_in_meters"] = [
                                        null,
                                        null,
                                      ];
                                      return newSelectedFilters;
                                    }
                                  );
                                }}
                              >
                                {lang("Clean Up")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={PriceIcon} />
                              <span>מחיר</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div>
                            <h6 className="">Price</h6>
                            <div className="price-range">
                              {/* <Input
                    id="minPrice"
                    name="total_price"
                    placeholder="USD "
                    onChange={(e) =>
                      handleSetVal("total_price", e.target.value)
                    }
                  /> */}
                              <Slider
                                className="slaider-row"
                                min={1}
                                max={
                                  debouncedActiveButton === "buy"
                                    ? 10000000
                                    : 15000
                                }
                                range={{
                                  draggableTrack: true,
                                }}
                                tooltip={{ open: false }}
                                value={priceLimit}
                                onChange={setPriceLimitFilter} // use onChange instead of onchange
                              />
                              <div className="count-box">
                                <div className="count-detail">
                                  <span>
                                    {priceLimit
                                      ? new Intl.NumberFormat().format(
                                        priceLimit[0]
                                      )
                                      : "0"}
                                    {"  "}₪
                                  </span>
                                  <span>

                                    +{priceLimit
                                      ? new Intl.NumberFormat().format(
                                        priceLimit[1]
                                      )
                                      : "100,000"}
                                    {"  "}₪
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="drp-dawn-btn">
                              {/* <button className="cho-btns">Choose</button> */}
                              <button
                                className="cl-btns"
                                onClick={() => {
                                  {
                                    debouncedActiveButton === "buy"
                                      ? setPriceLimit([0, 10000000])
                                      : setPriceLimit([0, 15000]);
                                  }

                                  setSelectedFiltersMulti(
                                    (prevSelectedFilters) => {
                                      const newSelectedFilters = {
                                        ...prevSelectedFilters,
                                      };
                                      // newSelectedFilters["total_price"] = [];
                                      delete newSelectedFilters["total_price"];
                                      return newSelectedFilters;
                                    }
                                  );
                                }}
                              >
                                {lang("Clean up")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="panel-button-drp">
                          <div className="panel-header">
                            <Button className="icon-item">
                              <img src={SortByIcon} />
                              <span>מיין לפי</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sub-panel-items">
                          <div className="short-by-cls">
                            <Select
                              placeholder="Select Sortable Value"
                              onChange={(values) => handleSetSort(values)}
                            >
                              <Select.Option value="old_to_new">
                                Date: from Old to New
                              </Select.Option>
                              <Select.Option value="new_to_old">
                                Date: from New to Old
                              </Select.Option>
                              <Select.Option value="low_to_high">
                                Price: from Low to High
                              </Select.Option>
                              <Select.Option value="high_to_low">
                                Price: from High to Low
                              </Select.Option>
                            </Select>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown className="main-second-screen-time-out">
                        <Dropdown.Toggle className="panel-button-drp short-by-cls ">
                          <div className="panel-header">
                            <Button className="filter-bt">
                              <img src={ScreenOutIcon} />
                              <span>מסך החוצה</span>
                            </Button>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          id="screen-filter"
                          className="sub-panel-items"
                        >
                          <div className="short-by-cls ">
                            <Row gutter={[24, 24]} className="flex-wrap">
                              <Col span={24} lg={12}>
                                <div className="toglle-section">
                                  <div className="prt-headding">
                                    <label>{lang('For rent/sale')}</label>
                                  </div>
                                  <div className="toggle-filter">
                                    <button
                                      className={`rent-btns ${activeButton === "rent" ? "active" : ""
                                        }`}
                                      onClick={() => handleButtonClick("rent")}
                                    >
                                      <img src={ForrentIcon} />
                                      {lang('For Rent')}
                                    </button>
                                    <button
                                      className={`rent-btns ${activeButton === "buy" ? "active" : ""
                                        }`}
                                      onClick={() => handleButtonClick("buy")}
                                    >
                                      <img src={ForsaleIcon} />
                                      {lang('For Sale')}
                                    </button>
                                    <button
                                      className={`rent-btns ${activeButton === "commercial_rent" ? "active" : ""
                                        }`}
                                      onClick={() => handleButtonClick("commercial_rent")}
                                    >
                                      <img src={ForsaleIcon} />
                                      {lang('For Commercial Rent')}
                                    </button>

                                    <button
                                      className={`rent-btns ${activeButton === "commercial_buy" ? "active" : ""
                                        }`}
                                      onClick={() => handleButtonClick("commercial_buy")}
                                    >
                                      <img src={ForsaleIcon} />
                                      {lang('For Commercial Buy')}
                                    </button>
                                  </div>
                                </div>
                              </Col>
                              <Col span={24} lg={12}>
                                <div className="prt-headding">
                                  <label>Property Type</label>
                                </div>
                                <div className="checkbox-menu-items">
                                  <Select
                                    className="select-proprty"
                                    mode="multiple"
                                    placeholder="Select property types"
                                    defaultValue={
                                      activeChecks ? activeChecks : []
                                    }
                                    onChange={handleOptionTypeChange}
                                    options={options12.map((option) => ({
                                      label: option.text,
                                      value: option.value,
                                    }))}
                                  ></Select>
                                </div>
                              </Col>

                              <Col span={24} lg={12}>
                                <div className="prt-headding">
                                  <label>Price</label>
                                </div>
                                <div className="price-range">
                                  <Slider
                                    className="slaider-row"
                                    min={1}
                                    max={10000000}
                                    range={{
                                      draggableTrack: true,
                                    }}
                                    value={priceLimit}
                                    onChange={setPriceLimitFilter} // use onChange instead of onchange
                                  />
                                  <div className="count-box">
                                    <div className="count-detail">
                                      <span>
                                        {priceLimit
                                          ? new Intl.NumberFormat().format(
                                            priceLimit[0]
                                          )
                                          : "0"}
                                        {"  "}
                                        ₪
                                      </span>
                                      <span>
                                        +{priceLimit
                                          ? new Intl.NumberFormat().format(
                                            priceLimit[1]
                                          )
                                          : "100,000"}
                                        {"  "}
                                        ₪
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col span={24} lg={12}>
                                <div className="prt-headding">
                                  <label>Area in square meters</label>
                                </div>
                                <div className="two-inn-filter">
                                  <Input
                                    placeholder="maximum"
                                    onChange={(e) =>
                                      handleSetMultipleSize(
                                        "size_in_meters",
                                        e.target.value,
                                        0
                                      )
                                    }
                                  />
                                  <Input
                                    placeholder="minimum"
                                    onChange={(e) =>
                                      handleSetMultipleSize(
                                        "size_in_meters",
                                        e.target.value,
                                        1
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                              <Col span={24} lg={12}>
                                <div className="toglle-section">
                                  <div className="prt-headding">
                                    <label>{lang("For Broker/Non-Broker")}</label>
                                  </div>
                                  <div className="panel-box-coll">
                                    <div className="toggle-filter">
                                      <button
                                        className={`rent-btns ${brokerActive === "broker"
                                          ? "active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          handleFilterBroker("broker")
                                        }
                                      >
                                        {lang("For Broker")}
                                      </button>
                                      <button
                                        className={`rent-btns ${brokerActive === "no_broker"
                                          ? "active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          handleFilterBroker("no_broker")
                                        }
                                      >
                                        {lang("For Non-Broker")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col span={24} lg={12}>
                                <div className="prt-headding"></div>
                                <h6 className="">Amenities</h6>
                                <div className="checkbox-menu-items">
                                  <div className="pro-type">
                                    {optionsAmeni.map((option) => (
                                      <Checkbox
                                        value={option.value}
                                        checked={activeChecksAmenity.includes(
                                          option.value
                                        )}
                                        onChange={(e) => {
                                          handleSetMultiple(
                                            "amenities",
                                            e.target.value,
                                            e.target.checked
                                          );
                                          handleFocusCA(
                                            e.target.value,
                                            e.target.checked
                                          );
                                        }}
                                      >
                                        {option.text}
                                      </Checkbox>
                                    ))}

                                    {/* <Select
                                      className="select-amenities"
                                      mode="multiple"
                                      placeholder="Select Amenities"
                                      defaultValue={
                                        activeChecksAmenity ? activeChecksAmenity : []
                                      }
                                      onChange={handleAmenitiesTypeChange}
                                      options={optionsAmeni.map((option) => ({
                                        label: option.text,
                                        value: option.value,
                                      }))}
                                    ></Select> */}
                                  </div>
                                </div>
                              </Col>

                              <Col span={24} lg={12}>
                                <div className="prt-headding">
                                  <label>Rooms</label>
                                </div>
                                <div
                                  className="radio-option bedrooms-list"
                                  data-bdnum="1"
                                  id="bedrooms-list"
                                >
                                  {[1, 2, 3, 4, 5, 6].map((num) => (
                                    <button
                                      key={num}
                                      id={`minPrice${num}`}
                                      className={`rooms-input ${selectedRooms.includes(num)
                                        ? "active"
                                        : ""
                                        }`}
                                      name="rooms_number"
                                      onFocus={() =>
                                        handleFocusW(`minPrice${num}`)
                                      }
                                      onBlur={() =>
                                        handleBlurW(`minPrice${num}`)
                                      }
                                      onClick={() => handleSetValW(num)}
                                    >
                                      {num === 6 ? "6+" : num}
                                    </button>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        {/* <Dropdown.Toggle className="panel-button-drp no-menu">
                      <div className="publick-asset">
                        <button
                          className={`rent-btns ${
                            activeButton === "buy" ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick("buy")}
                        >
                          <img src={ForsaleIcon} />
                          For Sale
                        </button>
                      </div>
                    </Dropdown.Toggle> */}
                      </Dropdown>

                      <Dropdown>
                        {/* <Dropdown.Toggle className="panel-button-drp no-menu">
                      <div className="publick-asset">
                        <button
                          className={`rent-btns ${
                            activeButton === "rent" ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick("rent")}
                        >
                          <img src={ForrentIcon} />
                          for rent
                        </button>
                      </div>
                    </Dropdown.Toggle> */}
                      </Dropdown>
                      <div className="main-mobile-container-main-9jj">
                        <div className="select-container main-mobile-container">
                          <Select
                            value={activeButton}
                            onChange={handleSelectChange}
                            style={{ width: 200 }}
                            className="rent-select"
                          >
                            <Option value="rent">
                              <img
                                src={ForrentIcon}
                                alt="For Rent"
                                style={{ marginRight: 8 }}
                              />
                              להשכרה
                            </Option>
                            <Option value="buy">
                              <img
                                src={ForsaleIcon}
                                alt="For Sale"
                                style={{ marginRight: 8 }}
                              />
                              למכירה
                            </Option>
                            <Option value="commercial_rent">
                              <img
                                src={ForsaleIcon}
                                alt="For Commercial Rent"
                                style={{ marginRight: 8 }}
                              />
                              {lang("commercial_rent")}
                            </Option>
                            <Option value="commercial_buy">
                              <img
                                src={ForsaleIcon}
                                alt="For Commercial Buy"
                                style={{ marginRight: 8 }}
                              />
                              {lang("commercial_buy")}
                            </Option>
                          </Select>
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle className="panel-button-drp no-menu">
                            <div className="publick-asset">
                              <Switch
                                checkedChildren="מפה"
                                unCheckedChildren="רשימה"
                                checked={mapTrue}
                                onClick={(mapTrue) => handleChangeMap(mapTrue)}
                                className="maps-changes-check"
                              />
                            </div>
                          </Dropdown.Toggle>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle className="panel-button-drp no-menu">
                            <div className="lead-clint-drop">
                              <Switch
                                checkedChildren={lang("Card")}
                                unCheckedChildren={lang("List")}
                                checked={listParent}
                                onClick={(listParent) =>
                                  onChangeParentList(listParent)
                                }
                                className="main-mecu-switch"
                              />
                            </div>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                    <div className=" d-block d-md-none">
                      <div class="ex-filter-add">
                        {brokerActive && (
                          <span class="roomnum">
                            {" "}
                            Broker:{" "}
                            {brokerActive == "no_broker"
                              ? "No Broker"
                              : "Broker"}{" "}
                          </span>
                        )}
                        {selectedRooms && selectedRooms.length > 0 && (
                          <span class="roomnum"> Rooms: {getRangeText()} </span>
                        )}
                        {activeChecks &&
                          activeChecks.length > 0 &&
                          activeChecks.map((data) => (
                            <span class="alaptyps">{data}</span>
                          ))}

                        {activeChecksAmenity &&
                          activeChecksAmenity.length > 0 &&
                          activeChecksAmenity.map((data) => (
                            <span class="alaptyps">{data}</span>
                          ))}
                        {selectedFiltersMulti["size_in_meters"][0] &&
                          selectedFiltersMulti["size_in_meters"].length > 0 && (
                            <span class="roomnum">
                              {" "}
                              Size:{" "}
                              {selectedFiltersMulti["size_in_meters"][0] +
                                "-" +
                                selectedFiltersMulti["size_in_meters"][1] +
                                "square meters"}{" "}
                            </span>
                          )}

                        {priceLimit && priceLimit.length > 0 ? (
                          <span class="pricerng">
                            Price: NIS ₪
                            {new Intl.NumberFormat().format(priceLimit[0])} -
                            NIS ₪ {priceLimit[1] >= 10000000 || priceLimit[1] >= 15000 ? " +" : ""}{new Intl.NumberFormat().format(
                              priceLimit[1]
                            )}
                          </span>
                        ) : (
                          <span class="pricerng">
                            Price: NIS 0 - NIS 10,000,000+
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {!mapTrue ? (
              <>
                <Col span={24}>
                  <div style={{ width: "100%", height: "700px" }}>
                    <div
                      ref={mapContainerRef}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <>
                {!listParent ? (
                  <Row gutter={24}>
                    <Col span={24} md={12} className="main-map-hide-class">
                      <div style={{ width: "100%", height: "700px" }}>
                        <div
                          ref={mapContainerRef}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </Col>
                    <Col span={24} md={12}>
                      {loading ? (
                        <Spin className="lodding-loder" />
                      ) : (
                        <>
                          <div className="section-properties">
                            <Row>
                              <Col span={24} lg={12}>
                                <div className="headding-left">
                                  <h4>
                                    {lang("Properties For")}
                                    {" "}
                                    {activeButton === "rent" ? lang('Rent') : activeButton === "commercial_buy" ? lang('commercial_buy') : activeButton === "commercial_rent" ? lang('commercial_rent') : lang('Sale')}
                                  </h4>
                                </div>
                              </Col>

                              <Col span={24} lg={12}>
                                <div className="result-grid-menu">
                                  <div className="list-menu-box">
                                    <Button
                                      className={`card-listing-body ${!listTrue ? "active" : ""
                                        }`}
                                      onClick={(e) => onChangeList(false)}
                                    >
                                      <FormatListBulletedOutlinedIcon />
                                    </Button>
                                    <Button
                                      className={`card-listing-body ${listTrue ? "active" : ""
                                        }`}
                                      onClick={(e) => onChangeList(true)}
                                    >
                                      <DashboardOutlinedIcon />
                                    </Button>
                                  </div>
                                  <div className="price-result">
                                    {totalList} {lang("Results")}
                                  </div>
                                </div>
                              </Col>
                              <Col span={24} className=" d-none d-md-block">
                                <div class="extraselecteddata">
                                  {brokerActive && (
                                    <span class="roomnum">
                                      {" "}
                                      Broker:{" "}
                                      {brokerActive == "no_broker"
                                        ? "No Broker"
                                        : "Broker"}{" "}
                                    </span>
                                  )}
                                  {selectedRooms &&
                                    selectedRooms.length > 0 && (
                                      <span class="roomnum">
                                        {" "}
                                        Rooms: {getRangeText()}{" "}
                                      </span>
                                    )}
                                  {activeChecks &&
                                    activeChecks.length > 0 &&
                                    activeChecks.map((data) => (
                                      <span class="alaptyps">{data}</span>
                                    ))}

                                  {activeChecksAmenity &&
                                    activeChecksAmenity.length > 0 &&
                                    activeChecksAmenity.map((data) => (
                                      <span class="alaptyps">{data}</span>
                                    ))}
                                  {selectedFiltersMulti["size_in_meters"][0] &&
                                    selectedFiltersMulti["size_in_meters"]
                                      .length > 0 && (
                                      <span class="roomnum">
                                        {" "}
                                        Size:{" "}
                                        {selectedFiltersMulti[
                                          "size_in_meters"
                                        ][0] +
                                          "-" +
                                          selectedFiltersMulti[
                                          "size_in_meters"
                                          ][1] +
                                          "square meters"}{" "}
                                      </span>
                                    )}

                                  {/* <span class="alaptyps">Apartment</span>
                        <span class="alaptyps">Garden Apartment</span>
                        <span class="alaptyps">Townhouse</span> */}
                                  {priceLimit && priceLimit.length > 0 ? (
                                    <span class="pricerng">
                                      Price: NIS ₪
                                      {new Intl.NumberFormat().format(
                                        priceLimit[0]
                                      )}{" "}
                                      - NIS ₪
                                      {priceLimit[1] >= 10000000 || priceLimit[1] >= 15000 ? " +" : ""}{new Intl.NumberFormat().format(
                                        priceLimit[1]
                                      )}

                                    </span>
                                  ) : (
                                    <span class="pricerng">
                                      Price: NIS 0 - NIS 10,000,000+
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <div className="sale-body-inner">
                              {listTrue ? (
                                <>
                                  <Row gutter={[24, 24]}>
                                    {list.map((item) => (
                                      <>
                                        <Col
                                          span={24}
                                          xl={12}
                                          className="col-p-sm"
                                        >
                                          <a
                                            className=""
                                            onClick={() =>
                                              navigate(
                                                `/asset-view/${item._id}`
                                              )
                                            }
                                          >
                                            <div className="slider-pro-block">
                                              <div className="swiper img-slider1">
                                                <div className="swiper-wrapper">
                                                  <div className="swiper-slide">
                                                    <img
                                                      loading="lazy"
                                                      src={
                                                        item.multiImage.length >
                                                          0
                                                          ? item.multiImage[0]
                                                          : HomeImage
                                                      }
                                                      alt="Palm Dom 4 Hadera"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="swiper-nav-inner d-none">
                                                  <div className="swiper-inner-btn swiper-button-prev">
                                                    <svg
                                                      width="7"
                                                      height="12"
                                                      viewBox="0 0 7 12"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.14648 11L1.0977 6L6.14648 1"
                                                        fill="white"
                                                      ></path>
                                                      <path
                                                        d="M6.14648 11L1.0977 6L6.14648 1"
                                                        stroke="#102846"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <div className="swiper-inner-btn swiper-button-next">
                                                    <svg
                                                      width="7"
                                                      height="12"
                                                      viewBox="0 0 7 12"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0.853516 11L5.90228 6L0.853515 1"
                                                        fill="white"
                                                      ></path>
                                                      <path
                                                        d="M0.853516 11L5.90228 6L0.853515 1"
                                                        stroke="#102846"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="slider-block-content">
                                                <div className="content-between mb-30">
                                                  <div className="price-locate-data">
                                                    <div className="price-block">
                                                      {new Intl.NumberFormat().format(
                                                        item.total_price
                                                      )}{" "}
                                                      ₪
                                                    </div>
                                                    <div className="location">
                                                      <svg
                                                        width="13"
                                                        height="15"
                                                        viewBox="0 0 13 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                                          stroke="#464A6B"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                                          stroke="#464A6B"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                      <span>
                                                        {item.location.city}{" "}
                                                        {
                                                          item.location
                                                            .neighborhood
                                                        }{" "}
                                                        {item.location
                                                          .street === "0"
                                                          ? ""
                                                          : item.location
                                                            .street}{" "}
                                                        {item.location
                                                          .street_number === "0"
                                                          ? ""
                                                          : item.location
                                                            .street_number}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="dlx-btns"
                                                    onClick={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                  >
                                                    {item.mobile_number ? (
                                                      <a
                                                        // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                                                        // href={`https://wa.me/+`}
                                                        href={
                                                          "https://wa.me/" +
                                                          cleanMobileNumber(
                                                            item.mobile_number
                                                          )
                                                        }
                                                        // target="_blank"
                                                        type="button"
                                                        title="Add to wishlist"
                                                        data-id="135362"
                                                        className="wishlisted"
                                                      >
                                                        <svg
                                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                          focusable="false"
                                                          aria-hidden="true"
                                                          viewBox="0 0 24 24"
                                                          data-testid="WhatsAppIcon"
                                                        >
                                                          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                                        </svg>
                                                      </a>
                                                    ) : null}
                                                    {!item.broker_id.includes(
                                                      userProfile._id
                                                    ) && (
                                                        <Button
                                                          onClick={() =>
                                                            onHandleClick(
                                                              item._id
                                                            )
                                                          }
                                                          className="add-btn"
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    <a
                                                      href={`callto:${cleanMobileNumber(
                                                        item.mobile_number
                                                      )}`}
                                                      // target="_blank"
                                                      className="wishlisted"
                                                    >
                                                      <PhoneOutlined />
                                                    </a>
                                                  </div>
                                                </div>
                                                <ul className="list-unstyled mb-0 property-list">
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={DoubleBedIcon}
                                                      />
                                                      <span>rooms</span>
                                                    </div>
                                                    <span>
                                                      {item.rooms_number}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={ProprtFlorIcon}
                                                      />
                                                      <span>floor</span>
                                                    </div>
                                                    <span>
                                                      {item.floor_number}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={HomeProprtyIcon}
                                                      />
                                                      <span>Property Type</span>
                                                    </div>
                                                    <span>
                                                      {item.property_type}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img src={SquareIcon} />
                                                      <span>size</span>
                                                    </div>
                                                    <span>
                                                      {item.size_in_meters}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </a>
                                        </Col>
                                      </>
                                    ))}
                                  </Row>
                                </>
                              ) : (
                                <>
                                  <Row gutter={[24, 24]}>
                                    ``
                                    {list.map((item) => (
                                      <>
                                        <Col span={24}>
                                          <a
                                            className="property-list-view"
                                            onClick={() =>
                                              navigate(
                                                `/asset-view/${item._id}`
                                              )
                                            }
                                          >
                                            <div className="slider-pro-block">
                                              <div className="swiper img-slider1">
                                                <div className="swiper-wrapper">
                                                  <div className="swiper-slide">
                                                    <img
                                                      loading="lazy"
                                                      src={
                                                        item.multiImage.length >
                                                          0
                                                          ? item.multiImage[0]
                                                          : HomeImage
                                                      }
                                                      alt="Palm Dom 4 Hadera"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="swiper-nav-inner d-none">
                                                  <div className="swiper-inner-btn swiper-button-prev">
                                                    <svg
                                                      width="7"
                                                      height="12"
                                                      viewBox="0 0 7 12"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M6.14648 11L1.0977 6L6.14648 1"
                                                        fill="white"
                                                      ></path>
                                                      <path
                                                        d="M6.14648 11L1.0977 6L6.14648 1"
                                                        stroke="#102846"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <div className="swiper-inner-btn swiper-button-next">
                                                    <svg
                                                      width="7"
                                                      height="12"
                                                      viewBox="0 0 7 12"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0.853516 11L5.90228 6L0.853515 1"
                                                        fill="white"
                                                      ></path>
                                                      <path
                                                        d="M0.853516 11L5.90228 6L0.853515 1"
                                                        stroke="#102846"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="slider-block-content">
                                                <div className="content-between mb-30">
                                                  <div className="price-locate-data">
                                                    <div className="price-block">
                                                      {new Intl.NumberFormat().format(
                                                        item.total_price
                                                      )}{" "}
                                                      ₪
                                                    </div>
                                                    <div className="location">
                                                      <svg
                                                        width="13"
                                                        height="15"
                                                        viewBox="0 0 13 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                                          stroke="#464A6B"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                                          stroke="#464A6B"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                      <span>
                                                        {item.location.city}{" "}
                                                        {
                                                          item.location
                                                            .neighborhood
                                                        }{" "}
                                                        {item.location
                                                          .street === "0"
                                                          ? ""
                                                          : item.location
                                                            .street}{" "}
                                                        {item.location
                                                          .street_number === "0"
                                                          ? ""
                                                          : item.location
                                                            .street_number}
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div
                                                    className="dlx-btns"
                                                    onClick={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                  >
                                                    {item.mobile_number ? (
                                                      <a
                                                        href={
                                                          "https://wa.me/" +
                                                          cleanMobileNumber(
                                                            item.mobile_number
                                                          )
                                                        }
                                                        // target="_blank"
                                                        type="button"
                                                        title="Add to wishlist"
                                                        data-id="135362"
                                                        className="wishlisted"
                                                      >
                                                        <svg
                                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                          focusable="false"
                                                          aria-hidden="true"
                                                          viewBox="0 0 24 24"
                                                          data-testid="WhatsAppIcon"
                                                        >
                                                          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                                        </svg>
                                                      </a>
                                                    ) : null}
                                                    {!item.broker_id.includes(
                                                      userProfile._id
                                                    ) && (
                                                        <Button
                                                          onClick={(e) => {
                                                            onHandleClick(
                                                              item._id
                                                            );
                                                          }}
                                                          className="add-btn"
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    <a
                                                      href={`callto:${cleanMobileNumber(
                                                        item.mobile_number
                                                      )}`}
                                                      // target="_blank"
                                                      className="wishlisted"
                                                    >
                                                      <PhoneOutlined />
                                                    </a>
                                                  </div>
                                                </div>
                                                <ul className="list-unstyled mb-0 property-list">
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={DoubleBedIcon}
                                                      />
                                                      <span>rooms</span>
                                                    </div>
                                                    <span>
                                                      {item.rooms_number}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={ProprtFlorIcon}
                                                      />
                                                      <span>floor</span>
                                                    </div>
                                                    <span>
                                                      {item.floor_number}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img
                                                        src={HomeProprtyIcon}
                                                      />
                                                      <span>Property Type</span>
                                                    </div>
                                                    <span>
                                                      {item.property_type}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <div className="item-name">
                                                      <img src={SquareIcon} />
                                                      <span>size</span>
                                                    </div>
                                                    <span>
                                                      {item.size_in_meters}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </a>
                                        </Col>
                                      </>
                                    ))}
                                  </Row>
                                </>
                              )}
                              <Pagination
                                className="pagination-section"
                                current={pagination.current}
                                pageSize={pagination.pageSize}
                                total={pagination.total}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                pageSizeOptions={["10", "20", "30", "50"]}
                                onShowSizeChange={(current, size) => {
                                  setPagination((prev) => ({
                                    ...prev,
                                    pageSize: size,
                                    current: 1, // Reset to the first page
                                  }));
                                  fetchData(
                                    {
                                      ...pagination,
                                      pageSize: size,
                                      current: 1,
                                    },
                                    filter
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={24}>
                    <Col xs={24} xl={24}>
                      <Row>
                        <Col span={24} lg={12}>
                          <div className="headding-left">
                            <h4>
                              Properties For{" "}
                              {activeButton === "rent" ? "Rent" : "Sale"}
                            </h4>
                          </div>
                        </Col>

                        <Col span={24} lg={12}>
                          <div className="result-grid-menu">
                            <div className="price-result">
                              {totalList} {lang("Results")}
                            </div>
                          </div>
                        </Col>
                        <Col span={24} className=" d-none d-md-block">
                          <div class="extraselecteddata">
                            {brokerActive && (
                              <span class="roomnum">
                                {" "}
                                Broker:{" "}
                                {brokerActive == "no_broker"
                                  ? "No Broker"
                                  : "Broker"}{" "}
                              </span>
                            )}
                            {selectedRooms && selectedRooms.length > 0 && (
                              <span class="roomnum">
                                {" "}
                                Rooms: {getRangeText()}{" "}
                              </span>
                            )}
                            {activeChecks &&
                              activeChecks.length > 0 &&
                              activeChecks.map((data) => (
                                <span class="alaptyps">{data}</span>
                              ))}
                            {activeChecksAmenity &&
                              activeChecksAmenity.length > 0 &&
                              activeChecksAmenity.map((data) => (
                                <span class="alaptyps">{data}</span>
                              ))}
                            {selectedFiltersMulti["size_in_meters"][0] &&
                              selectedFiltersMulti["size_in_meters"].length >
                              0 && (
                                <span class="roomnum">
                                  {" "}
                                  Size:{" "}
                                  {selectedFiltersMulti["size_in_meters"][0] +
                                    "-" +
                                    selectedFiltersMulti["size_in_meters"][1] +
                                    "square meters"}{" "}
                                </span>
                              )}
                            {priceLimit && priceLimit.length > 0 ? (
                              <span class="pricerng">
                                Price: NIS ₪
                                {new Intl.NumberFormat().format(priceLimit[0])}{" "}
                                - NIS ₪
                                {priceLimit[1] >= 10000000 || priceLimit[1] >= 15000 ? " +" : ""}{new Intl.NumberFormat().format(
                                  priceLimit[1]
                                )}
                              </span>
                            ) : (
                              <span class="pricerng">
                                Price: NIS 0 - NIS 10,000,000+
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div className="table-responsive customPagination mt-5">
                        <Table
                          loading={loading}
                          columns={columns}
                          dataSource={list}
                          rowSelection={rowSelection}
                          // onChange={handlePageChange}
                          pagination={{
                            defaultPageSize: 10,
                            responsive: true,
                            total: pagination.total,
                            // showSizeChanger: true,
                            // showQuickJumper: true,
                            // pageSizeOptions: ["10", "20", "30", "50"],
                          }}
                          onChange={handleChange}
                          className="ant-border-space"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
}

export default Index;
