import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Space,
  Switch,
  TimePicker,
  Select,
} from "antd";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import lang from "../../helper/langHelper";
import signinLogo from "../../assets/images/Logo.png";
import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";
import { Severty, ShowToast } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";

const { Header, Content } = Layout;

const foodCategoryList = [
  { name: "Pizza", _id: "Pizza" },
  { name: "burger", _id: "burger" },
  { name: "italian", _id: "italian" },
];
const dummyDeliveryType = [
  { name: "Delivery", _id: "Delivery" },
  { name: "Pickup", _id: "Pickup" },
];

const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];
const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  // "image/avif",
  // "image/webp",
  // "image/gif",
];
const dummyCountries = [
  { name: "US", _id: "1" },
  { name: "India", _id: "2" },
  { name: "Canada", _id: "3" },
  { name: "Dubai", _id: "4" },
];

const Register = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const api = {
    signUp: apiPath.signUp,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const [logo, setLogo] = useState();
  // const [document, setDocument] = useState();

  const [image, setImage] = useState();

  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState([]);

  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [location, setLocation] = useState();

  const [foodCategories, setFoodCategories] = useState([]);
  const [restaurantCategories, setRestaurantCategories] = useState([]);

  const [userAddress, setUserAddress] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [secondaryMobile, setSecondaryMobile] = useState(null);

  const [selectedTime, setSelectedTime] = useState({
    open: null,
    close: null,
  });

  const { request } = useRequest();

  const onFinish = (values) => {
    console.log(values, "hfjhdkghkhdgkd");
    const { location } = values;
    let payload = {
      ...values,
      image: image,
      logo: logo,
      ...mobileNumber,
      ...location,
      // document: document
    };
    console.log(payload, "payload");
    register(payload);
  };

  useEffect(() => {
    console.log(selectedTime, "selectedTime");
  }, [selectedTime]);

  const register = (payload) => {
    request({
      url: api.signUp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "data--->");
        if (data.status) {
          // Ensure the email verification message is shown
          // ShowToast("Verification Email sent to your email", Severty.SUCCESS);

          // Navigate to verify-email page
          // setTimeout(() => navigate("/verify-email"), 200);
          generatePaymentLink()
          // Set user session
          setIsLoggedIn(true);

          // Clear localStorage (if needed)
          localStorage.removeItem("ykmCe2AYEFTHobn");
          localStorage.removeItem("ouUsippetc8S4Ry");

          // Store token and user profile
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));

          // Show success message and update user profile context
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);

        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const generatePaymentLink = () => {
    request({
      url: "/common/generate-payment-link",
      method: "get",
      data: {},
      onSuccess: (data) => {
        setLoading(false);
        console.log(data.data, "fghdjh data");
        if (data.status) {
          window.location.href = `https://api.takbull.co.il/PaymentGateway?orderUniqId=${data.data.uniqId}`
          // ShowToast(data.message, Severty.SUCCESS);
          // setTimeout(() => navigate(``), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  }

  const onFinishFailed = () => { };

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data);
        }
      },
    });
  };

  const getCategory = () => {
    request({
      url: api.rc,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurantCategories(data); // TODO: change dummy
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getFoodCategory = () => {
    request({
      url: api.fc,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setFoodCategories(foodCategoryList); // TODO: change dummy
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleUploadLogo = (data) => {
    setLogo(data[0]?.url);
  };

  // const handleUploadDocument = (data) => {
  //   setDocument(data[0]?.url);
  // };

  const handleMobileNumberChange = (value, data, type) => {
    let countryCode = data?.dialCode;
    setMobileNumber({
      countryCode: countryCode,
      mobileNumber: value.slice(data?.dialCode?.length),
    });
  };
  useEffect(() => {
    getCountry();
    getCities();
    getCategory();
    getFoodCategory();
  }, []);

  const handleLocationChange = (value) => {
    setUserAddress(value.address);
    setlatLong({
      lat: parseFloat(value.latitude),
      lng: parseFloat(value.longitude),
    });
  };

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row>
          <Col span={24}>
            <div className="signin-box">
              <div className="sign-up-header">
                <div className="signup-logo">
                  <img src={signinLogo} />
                </div>
                <h3>{lang('Create Your Account')}</h3>
              </div>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Row gutter={20}>
                  <Col span={24} md={24}>
                    <Row gutter={20}>
                      <TextInputBox
                        name="name"
                        label="Full Name"
                        placeholder="Enter Full Name"
                        cover={{ md: 24 }}
                        colProps={{ sm: 24, span: 24 }}
                        rules={[
                          {
                            max: 250,
                            message:
                              "Name should contain more then 250 characters!",
                          },
                          {
                            required: true,
                            message: "Please Enter Full Name",
                          },
                        ]}
                      />
                      <Col span={24} md={24}>
                        <Form.Item
                          label="Email ID"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email address!",
                            },
                            {
                              max: 255,
                              message:
                                "Email address not more then 255 characters!",
                            },
                            {
                              required: true,
                              message: "Please enter email!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email ID" />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={24}>
                        <Form.Item label="Phone Number" name="mobile">
                          <PhoneNumberField
                            placeholder="Enter Phone Number"
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            className=""
                            onChange={handleMobileNumberChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the city",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select City"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={city?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} md={24}>
                        <Form.Item
                          label="Broker Licence Number"
                          name="brokerNo"
                          rules={[
                            {
                              required: true,
                              message: "Please enter broker No!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Broker No" />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={24}>
                        <Form.Item
                          label="Create Password"
                          name="password"
                          rules={[
                            {
                              pattern: new RegExp(
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                              ),
                              message:
                                "Confirm password at least contain 8 characters, at least contain one captital letter, at least contain one small letter, atleast contain one digit, atleast contain one special character",
                            },
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            autoComplete="off"
                            placeholder="Create Password"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24} md={24}>
                        <Form.Item
                          label="Confirm Password"
                          name="confirm_password"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Enter the confirm password!",
                            },

                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Password that you entered doesn't match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="Enter Confirm Password" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Row></Row>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <Button className="primary_btn btnStyle " htmlType="submit">
                    {lang('SUBMIT')}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Register;
