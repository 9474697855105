import React, { createContext, useState, useEffect, useContext, useLayoutEffect } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import axios from "axios";
import { Asset } from "../pages";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState({ token: null });
  const [isAdmin, setIsAdmin] = useState(true);
  const [userProfile, setUserProfile] = useState();

  const fetchUser = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiPath.baseURL + `${apiPath.profile}`, { headers });
      setUserProfile(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    const theme = localStorage.getItem('theme');
    const languageSet = localStorage.getItem('languageSet');

    if (theme) {
      const theme1 = theme === "Light" ? "Dark" : "Light"
      if (theme == "Dark") {
        //dark theme 
        console.log("dark.css");
        import("../assets/styles/dark.css");
      } else {
        console.log("main.css");
        import("../assets/styles/main.css");
      }
    } else {
      import("../assets/styles/main.css");
    }

    if(languageSet === "iw"){
      import("../assets/styles/rtl.css");
    }

  }, [])

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const user = localStorage.getItem("userProfile");
    if (user) {
      try {
        const parsedUser = JSON.parse(user);
        setUserProfile(parsedUser);
        fetchUser();
      } catch (e) {
        console.error("Failed to parse user profile:", e);
        // Handle error: Possibly clear invalid data from localStorage
        localStorage.removeItem("userProfile");
      }
    }
    setIsLoggedIn(true);
    setSession({ token: token });
  }, []);

  useEffect(() => {
    if (!userProfile) return;
    if (userProfile.type === 'SubAdmin') {
      setIsAdmin(false);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isLoggedIn) return;
  }, [isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);
    return <Navigate to="/dashboard" />;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    setIsLoggedIn(false);
    setSession({ token: null });
    setUserProfile();
    ShowToast("Logout Successfully", Severty.SUCCESS);
    return <Navigate to="/login" />;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        session,
        setSession,
        userProfile,
        setUserProfile,
        login,
        logout,
        isAdmin,
        setIsAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
