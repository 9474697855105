import { NotificationOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tabs, Input, Avatar, Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";

import ProfileImg from "../../assets/images/imagefor-profile.png";
import SuccessImg from "../../assets/images/ride-book-sucess.png";
import DsicountImg from "../../assets/images/discount21.png";
import apiPath from "../../constants/apiPath";
import PhoneInput from "react-phone-input-2";

import { useNavigate } from "react-router";

import { Severty, ShowToast } from "../../helper/toast";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import useRequest from "../../hooks/useRequest";
import UploadImage from "./_UploadImage";
const { TabPane } = Tabs;

function Index() {
  const [broker, setBroker] = useState();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [city, setCity] = useState([]);

  const heading = lang("My") + " " + lang("Tasks");
  const { setPageHeading } = useContext(AppStateContext);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [terms, setTerms] = useState("");
  const [image, setImage] = useState();
  const [mobileNumber, setMobileNumber] = useState({
    mobileNumber: "",
    countryCode: "",
  });

  const handleChange = (value, data) => {
    var countryCode = data.dialCode;
    setMobileNumber({
      countryCode: countryCode,
      mobileNumber: value.slice(data.dialCode.length),
    });
  };
  const api = {
    brokerData: apiPath.profile,
    updatePassword: apiPath.changePassword,
    updateProfile: apiPath.updateProfile,
  };

  useEffect(() => {
    setPageHeading(heading);
    fetchData({ ...pagination, current: 1 });
  }, [setPageHeading, refresh]);
  const [form] = Form.useForm();

  const [files, setFiles] = useState([]);

  const handleUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleRemove = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const [tabPosition, setTabPosition] = useState("left");
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    mobile: "",
    address: "",
    city: "",
    neighborhood: "",
    image: "",
  });

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.asset_status : null;

    request({
      url: api.brokerData,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        setBroker(data);

        setMobileNumber({
          mobileNumber: data.mobileNumber,
          countryCode: data.countryCode,
        });

        const formValues = {
          name: data?.name,
          last_name: data?.last_name,
          email: data?.email,
          mobile: data.countryCode + "" + data.mobileNumber,
          address: data?.address,
          city: data?.city,
          neighborhood: data?.neighborhood,
          image: data?.profileImage,
          brokerNo: data?.brokerNo,
        };
        if (data?.profileImage) {
          setImage(data.profileImage ? data.profileImage : "");
        }

        setFormData(formValues);

        form.setFieldsValue(formValues);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });

    request({
      url: apiPath.terms,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data.data[0], "data");
          const dataTerm = data.data[0];
          setTerms(dataTerm.description);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getCity();
  }, [setPageHeading]);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };
  const onCreate = (values) => {
    const {
      name,
      last_name,
      email,
      mobile,
      address,
      city,
      neighborhood,
      brokerNo,
    } = values;
    const payload = {};
    setLoading(true);
    payload.profileImage = image;
    payload.name = name;
    payload.email = email;
    payload.last_name = last_name;
    payload.mobileNumber = mobileNumber?.mobileNumber;
    payload.countryCode = mobileNumber?.countryCode;
    payload.city = city;
    payload.neighborhood = neighborhood;
    payload.address = address;
    payload.brokerNo = brokerNo;

    request({
      url: `${api.updateProfile}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefresh(true);
          window.location.reload();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onCreatePass = (values) => {
    const { old_password, new_password, co_password } = values;
    const payload = {};
    setLoading(true);
    payload.old_password = old_password;
    payload.new_password = new_password;
    payload.co_password = co_password;

    request({
      url: `${api.updatePassword}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          // navigate(-1);
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Col span={24}>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
          {lang('Back')}
        </Button>
      </Col>
      <Tabs
        defaultActiveKey="1"
        className="profile-tabs-for-brokers"
        tabPosition={tabPosition}
      >
        <TabPane
          tab={
            <span>
              <EditOutlinedIcon />
              {lang("Edit Profile")}
            </span>
          }
          key="1"
          className="budget-main"
        >
          <div className="main-profile-formm">
            <Form
              className="profile-input-boox"
              layout="vertical"
              form={form}
              onFinish={onCreate}
            >
              <Form.Item
                className="upload_wrap profile-upload"
                rules={[
                  {
                    validator: (_, value) => {
                      if (image) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Profile image is required"))
                      );
                    },
                  },
                ]}
                name="image"
              >
                <UploadImage value={image} setImage={setImage} />
              </Form.Item>

              <Row gutter={24}>
                <Col span={24} md={12} className="profile-input-boox">
                  <Form.Item label={lang("First Name")} name="name">
                    <Input value={broker?.name} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item label={lang("Last Name")} name="last_name">
                    <Input value={broker?.last_name} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={lang("Email")} name="email">
                    <Input value={broker?.email} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={lang("Phone Number")}
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        validator: (rule, value) => {
                          if (!value) {
                            return Promise.reject(
                              lang("Please enter phone number")
                            );
                          }
                          if (!/^\d{8,15}$/.test(value)) {
                            return Promise.reject(
                              lang("Phone number must be between 8 and 15 digits")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      inputProps={{
                        name: "mobile",
                        required: true,
                        autoFocus: false,
                        autoFormat: false,
                        autoComplete: "off",
                      }}
                      isValid={(value, country) => {
                        if (value.match(/1234/)) {
                          return "Invalid value: " + value + ", " + country.name;
                        } else {
                          return true;
                        }
                      }}
                      country={"il"}
                      preferredCountries={["il"]}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={lang("Address")} name="address">
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={lang("City")}
                    name="city"
                    rules={[{ required: true, message: lang("Please enter the city") }]}
                  >
                    <Select
                      className="city-select"
                      showSearch
                      placeholder={lang("Select City")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={city?.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={lang("Broker Licence No")} name="brokerNo">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className="for-profile-save-btn">
                <Button type="primary" htmlType="submit" block>
                  {lang("Save")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>

        {/* <TabPane
          tab={
            <span>
              {" "}
              <NotificationsNoneOutlinedIcon /> Notification
            </span>
          }
          key="2"
          className="budget-main"
        >
          <div className="all-notification-main-div">
            <div className="main-div-for-notifiaction">
              <div className="date-main-today-2">
                <h6>TODAY</h6>
                <p>Mark all as read</p>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={DsicountImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
            </div>

            <div className="main-div-for-notifiaction">
              <div className="date-main-today-2">
                <h6>YESTERDAY</h6>
                <p>Mark all as read</p>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={SuccessImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>

              <div className="bookride-main">
                <div className="notifiaction--main-icon">
                  <img src={DsicountImg} />
                </div>
                <div className="notifiaction--main-texts">
                  <div className="book-right-hours">
                    <h6>Ride Booked Successfully</h6> <p>1h</p>
                  </div>
                  <div className="ride-messagess">
                    Lorem ipsum dolor is amet , consectetur adipiscing Hii
                    Darrel , sit amet, conse ctetur adipiscing elit, sed do
                    eiusm utgd tempor adipiscing elit, sed do eiusm utgo tempor
                    incididunt ut labore
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane> */}
        <TabPane
          tab={
            <span>
              {" "}
              <GavelOutlinedIcon />{lang("Terms & Conditions")}
            </span>
          }
          key="3"
          className="budget-main"
        >
          <div className="terms-and-condition-page">
            <div className="main-terms-heading">{lang("Terms & Conditions")}</div>
            <div dangerouslySetInnerHTML={{ __html: terms }} />

            {/* <div className="main-paragraph-hedaing">
              <h4>Ride Booked Successfully First Ride .</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu
              </p>
            </div> */}
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              {" "}
              <GavelOutlinedIcon /> {lang("Change Password")}
            </span>
          }
          key="4"
          className="budget-main"
        >
          <div className="main-profile-formm">
            <Form
              className="profile-input-boox"
              layout="vertical"
              form={form}
              onFinish={onCreatePass}
            >
              <Form.Item label={`${lang("Old Password")}`} name="old_password">
                <Input.Password />
              </Form.Item>
              <Form.Item label={`${lang("New Password")}`} name="new_password">
                <Input.Password />
              </Form.Item>
              <Form.Item label={`${lang("Confirm New Password")}`} name="co_password">
                <Input.Password />
              </Form.Item>
              <Form.Item className="for-profile-save-btn">
                <Button type="primary" htmlType="submit" block>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}

export default Index;
